import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';

import IUser, { UserType } from 'shared/models/User';
import config from 'shared/config';
import IEmployeeAcknowledgment from 'shared/models/EmployeeAcknowledgment';
import IEmployeeQuizEvaluation from 'shared/models/EmployeeQuizEvaluation';
import uninterceptedAxiosInstance from 'shared/services/config';

export interface IUserCertificate {
	name: string;
	date: Date;
	score: number | null;
	baseLink: string;
	baseCeLink: string | null;
}

interface IUserProps {
	firstName?: string;
	lastName?: string;
	email?: string;
	allowOshaEmails?: boolean;
}

export default class UserService {
	public static async findAcknowledgments(
		id: string
	): Promise<IEmployeeAcknowledgment[]> {
		const user = await this.findById(id);
		if (!user.employeeAcknowledgments) {
			return [];
		}
		return user.employeeAcknowledgments.map((acknowledgment) => {
			return {
				...acknowledgment,
				link: this.getAcknowledgmentLink(user, acknowledgment)
			};
		});
	}

	public static async findCertificates(id: string): Promise<IUserCertificate[]> {
		const user = await this.findById(id);
		const certificates = await this.findCourseCertificates(id);

		const quizzes = user.employeeQuizEvaluations
			? this.mapQuizzes(user.id, user.employeeQuizEvaluations)
			: [];

		return [...quizzes, ...certificates];
	}

	public static async update(id: string, props: IUserProps): Promise<IUser> {
		const response = await axios.put(`/clients/${id}`, {
			...this.mapPropsToRequest(props)
		});

		return camelcaseKeys(response.data, {
			deep: true,
			stopPaths: ['personal_services', 'organization.personal_services']
		});
	}

	public static async updatePassword(
		currentPassword: string,
		newPassword: string
	): Promise<boolean> {
		return await uninterceptedAxiosInstance
			.put('/users/change_password', {
				current_password: currentPassword,
				new_password: newPassword
			})
			.then(() => {
				return true;
			})
			.catch(() => {
				return false;
			});
	}

	private static async findById(id: string): Promise<IUser> {
		const response = await axios.get(`/users/${id}`);
		return camelcaseKeys(response.data, {
			deep: true,
			stopPaths: ['personal_services', 'organization.personal_services']
		});
	}

	private static async findCourseCertificates(
		id: string
	): Promise<IUserCertificate[]> {
		const response = await axios.get(`/users/${id}/certificates`);
		return camelcaseKeys(response.data, { deep: true });
	}

	private static mapQuizzes(
		userId: string,
		quizzes: IEmployeeQuizEvaluation[]
	): IUserCertificate[] {
		return quizzes
			.filter((quiz) => quiz.submittedAt)
			.sort((a, b) => {
				return new Date(a.submittedAt).getTime() - new Date(b.submittedAt).getTime();
			})
			.map((quiz) => {
				return {
					name: quiz.quizName,
					date: new Date(quiz.submittedAt),
					score: quiz.score,
					baseLink: `${config.pdfUrl}/${userId}/quiz_certificate/${quiz.id}`,
					baseCeLink: null
				};
			});
	}

	private static getAcknowledgmentLink(
		user: IUser,
		acknowledgment: IEmployeeAcknowledgment
	): string {
		const documentUrls = {
			'HIPAA Policies': 'acknowledgment_hipaa_policies',
			'HIPAA Training': 'acknowledgment_hipaa_training'
		};

		const documentUrl = documentUrls[acknowledgment.documentType];
		if (user.type === UserType.FinancialInstitutionAdmin) {
			// TODO: define FI acknowledgment link
			return '';
		}

		const query = {
			name: user.organization!.name,
			name_person: `${user.firstName} ${user.lastName}`,
			signed_date: acknowledgment.signedDate
		};

		return `${config.pdfUrl}/${
			user.organization!.id
		}/${documentUrl}?${new URLSearchParams(query)}`;
	}

	private static mapPropsToRequest(props: IUserProps): object {
		return {
			first_name: props.firstName?.trim(),
			last_name: props.lastName?.trim(),
			email: props.email?.toLowerCase().trim(),
			allow_osha_emails: props.allowOshaEmails
		};
	}
}
