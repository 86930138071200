import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CourseCategory } from 'shared/models/Course';
import IUserCourse from 'shared/models/UserCourse';
import { listCourses } from './actions';

interface IState {
	// OSHA
	oshaSuccessMessage: JSX.Element | null;
	oshaCoursesLoading: boolean;
	oshaCourses: IUserCourse[];
	// HIPAA
	hipaaSuccessMessage: JSX.Element | null;
	hipaaCoursesLoading: boolean;
	hipaaCourses: IUserCourse[];
}

const initialState: IState = {
	// OSHA
	oshaCoursesLoading: false,
	oshaSuccessMessage: null,
	oshaCourses: [],
	// HIPAA
	hipaaCoursesLoading: false,
	hipaaSuccessMessage: null,
	hipaaCourses: []
};

export const coursesSlice = createSlice({
	name: 'courses',
	initialState,
	reducers: {
		// OSHA
		setOshaCourses: (state, action: PayloadAction<IUserCourse[]>) => {
			state.oshaCourses = action.payload;
		},
		setOshaSuccessMessage: (state, action: PayloadAction<JSX.Element | null>) => {
			state.oshaSuccessMessage = action.payload;
		},
		// HIPAA
		setHipaaCourses: (state, action: PayloadAction<IUserCourse[]>) => {
			state.hipaaCourses = action.payload;
		},
		setHipaaSuccessMessage: (state, action: PayloadAction<JSX.Element | null>) => {
			state.hipaaSuccessMessage = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(listCourses.fulfilled, (state, action) => {
				state.oshaCourses = action.payload.filter(
					(course) => course.category === CourseCategory.Osha
				);
				state.hipaaCourses = action.payload.filter(
					(course) => course.category === CourseCategory.Hipaa
				);
				state.oshaCoursesLoading = false;
				state.hipaaCoursesLoading = false;
			})
			.addCase(listCourses.pending, (state) => {
				state.oshaCoursesLoading = true;
				state.hipaaCoursesLoading = true;
			});
	}
});

export const {
	setOshaCourses,
	setOshaSuccessMessage,
	setHipaaCourses,
	setHipaaSuccessMessage
} = coursesSlice.actions;

export const coursesReducer = coursesSlice.reducer;
