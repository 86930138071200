import { createSlice } from '@reduxjs/toolkit';

import { getUserAcknowledgments, getUserCertificates } from './actions';
import IEmployeeAcknowledgment from 'shared/models/EmployeeAcknowledgment';
import { IUserCertificate } from 'user/services/UserService';

interface IState {
	userAcknowledgments: IEmployeeAcknowledgment[];
	userCertificates: IUserCertificate[];
	userAcknowledgmentsLoading: boolean;
	userCertificatesLoading: boolean;
}

const initialState: IState = {
	userAcknowledgmentsLoading: false,
	userCertificatesLoading: false,
	userAcknowledgments: [],
	userCertificates: []
};

export const userAccountInformationSlice = createSlice({
	name: 'userAccountInformation',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getUserAcknowledgments.fulfilled, (state, action) => {
				state.userAcknowledgments = action.payload;
				state.userAcknowledgmentsLoading = false;
			})
			.addCase(getUserAcknowledgments.pending, (state) => {
				state.userAcknowledgmentsLoading = true;
			})
			.addCase(getUserCertificates.fulfilled, (state, action) => {
				state.userCertificates = action.payload;
				state.userCertificatesLoading = false;
			})
			.addCase(getUserCertificates.pending, (state) => {
				state.userCertificatesLoading = true;
			});
	}
});

export const userAccountInformationReducer = userAccountInformationSlice.reducer;
