import { Lock } from '@material-ui/icons';
import { useDispatch } from 'react-redux';

import { adminPortalStyles } from './adminPortalStyles';
import { handleClasses } from 'shared/utils/handleClasses';
import { logout } from 'shared/store';
import { userPortalStyles } from './userPortalStyles';

interface ISignOutProps {
	portal: 'adminPortal' | 'userPortal';
}

const SignOut = ({ portal }: ISignOutProps) => {
	const classes = handleClasses(portal, adminPortalStyles(), userPortalStyles());
	const dispatch = useDispatch();

	const onLogout = () => dispatch(logout());

	return (
		<div className={classes.sidebarLink} onClick={onLogout}>
			<span className={classes.sidebarIcon}>
				<Lock />
			</span>
			<span className={classes.sidebarLabel}>Sign Out</span>
		</div>
	);
};

export default SignOut;
