import { Suspense, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';

import EnterpriseService from 'enterprise/services/EnterpriseService';
import Header from 'enterprise/components/header';
import Loader from 'shared/components/loader';
import routes from './routes';
import Sidebar from 'enterprise/components/sidebar';
import { styles } from './styles';

const PrivateRoutes = () => {
	const elements = useRoutes(routes);
	const classes = styles();

	// Pendo initialization
	useEffect(() => {
		const urlOrigin = window.location.origin.replace(/(^\w+:|^)\/\//, '') as string;
		const newWindowObject = window as any;
		const pendo = newWindowObject.pendo;
		EnterpriseService?.getPendoDataToSend(urlOrigin).then((response) => {
			pendo.initialize(response);
		});
	}, []);

	return (
		<div className={classes.contentWrapper}>
			<Sidebar />
			<div className={classes.appContent}>
				<Header />
				<div className={classes.suspenseWrapper}>
					<Suspense fallback={<Loader />}>{elements}</Suspense>
				</div>
			</div>
		</div>
	);
};

export default PrivateRoutes;
