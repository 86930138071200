export enum CourseType {
	Bbp = 'bbp',
	Hazcom = 'hazcom',
	Infection = 'osha_infection',
	Covid = 'covid19',
	Hipaa = 'hipaa_officer_training'
}

export enum CourseKey {
	Bbp = 'bbp_certificate',
	Hazcom = 'hazcom_certificate',
	Infection = 'osha_infection_certificate',
	Covid = 'covid19_certificate',
	Hipaa = 'hipaa_officer_training'
}

export enum CourseCategory {
	Osha = 'OSHA',
	Hipaa = 'HIPAA'
}

export default interface ICourse {
	id: string;
	type: CourseType;
	key: CourseKey;
	imagePath: string;
	title: string;
	category: CourseCategory;
	description: string;
	completionTime: string;
	required: boolean;
}
