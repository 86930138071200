import { createSlice } from '@reduxjs/toolkit';
import IOrganization from 'shared/models/Organization';

import { getExportedLeads } from './actions';

interface IState {
	exportedLeads: IOrganization[];
	exportedLeadsLoding: boolean;
	total: number;
}

const initialState: IState = {
	exportedLeadsLoding: false,
	exportedLeads: [],
	total: 0
};

export const exportedLeadsSlice = createSlice({
	name: 'exportedLeads',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getExportedLeads.fulfilled, (state, action) => {
				state.exportedLeads = action.payload.data;
				state.total = action.payload.total;
				state.exportedLeadsLoding = false;
			})
			.addCase(getExportedLeads.pending, (state) => {
				state.exportedLeadsLoding = true;
			});
	}
});

export const exportedLeadsReducer = exportedLeadsSlice.reducer;
