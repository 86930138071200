import { createStyles, makeStyles } from '@material-ui/core';

export const userPortalStyles = makeStyles(() =>
	createStyles({
		sideDrawerWrapper: {
			height: '100%',
			backgroundColor: '#4A91E2'
		},
		drawerToggle: {
			cursor: 'pointer',
			color: '#8E8C8E'
		},
		officeSafeLogo: {
			padding: '9px 6px !important',
			justifyContent: 'unset !important'
		}
	})
);
