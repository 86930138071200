import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';

import IUser from 'shared/models/User';

export default class UserService {
	public static async findById(id: string): Promise<IUser> {
		const response = await axios.get(`/users/${id}`);
		return camelcaseKeys(response.data, {
			deep: true,
			stopPaths: ['personal_services', 'organization.personal_services']
		});
	}
}
