import {
	AccountBalance,
	ArrowForward,
	BorderColor,
	BusinessCenter,
	Dashboard,
	Description,
	Folder,
	People,
	Person,
	Save
} from '@material-ui/icons';

import {
	ACCOUNT,
	ADMINS,
	CERTIFICATES,
	CLIENTS,
	COMPANIES,
	DASHBOARD,
	DOCUMENTS,
	EXPORT,
	GLOBAL_SETTINGS,
	MERCHANT_TEMPLATES,
	ORGANIZATIONS,
	REFERRALS,
	RESELLERS
} from 'admin/routes/pathNames';
import { AdminType } from 'shared/models/Admin';

interface ILink {
	label: string;
	icon: JSX.Element;
	route: string;
	isAccount?: boolean;
	adminTypes?: AdminType[];
}

export const links: ILink[] = [
	{
		label: 'Dashboard',
		icon: <Dashboard />,
		route: DASHBOARD,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin]
	},
	{
		label: 'Organizations',
		icon: <AccountBalance />,
		route: ORGANIZATIONS,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin]
	},
	{
		label: 'Referral Directory',
		icon: <Description />,
		route: REFERRALS,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin]
	},
	{
		label: 'Documents',
		icon: <Folder />,
		route: DOCUMENTS,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin]
	},
	{
		label: 'Resellers',
		icon: <People />,
		route: RESELLERS,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin]
	},
	{
		label: 'Client Directory',
		icon: <Description />,
		route: CLIENTS,
		adminTypes: [AdminType.PaymentAdmin, AdminType.PaymentMember]
	},
	{
		label: 'Merchant Templates',
		icon: <Save />,
		route: MERCHANT_TEMPLATES
	},
	{
		label: 'Companies',
		icon: <BusinessCenter />,
		route: COMPANIES,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin]
	},
	{
		label: 'Global Settings',
		icon: <BorderColor />,
		route: GLOBAL_SETTINGS,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin]
	},
	{
		label: 'Certificates',
		icon: <Description />,
		route: CERTIFICATES,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin]
	},
	{
		label: 'My Account',
		icon: <Person />,
		route: ACCOUNT,
		isAccount: true
	},
	{
		label: 'Users',
		icon: <People />,
		route: ADMINS,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin, AdminType.PaymentAdmin]
	},
	{
		label: 'Export Data',
		icon: <ArrowForward />,
		route: EXPORT,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin]
	}
];
