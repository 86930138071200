import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';

import IOrganization, {
	OrganizationRaStatus,
	OrganizationStatus
} from 'shared/models/Organization';
import IPaginatedResponse from 'shared/services/PaginatedResponse';
import IUser from 'shared/models/User';

export interface ISearchProps {
	limit: number;
	offset: number;
	status: OrganizationStatus | string;
	order?: 'asc' | 'desc';
	sources?: string[];
	fromDate?: Date | null;
	toDate?: Date | null;
	raStatus?: OrganizationRaStatus | null;
	isExported?: boolean;
}

export default class ClientService {
	public static async list(
		props: ISearchProps
	): Promise<IPaginatedResponse<IOrganization>> {
		const response = await axios.get('/clients', {
			params: {
				limit: props.limit,
				offset: props.offset,
				paginated: true,
				status: props.status,
				from_date: props.fromDate,
				till_date: props.toDate,
				sources: props.sources?.join(' '),
				sort: 'created_at',
				order: props.order || 'desc',
				last_ra_status: props.raStatus,
				is_exported: props.isExported
			}
		});

		return {
			total: response.data.total,
			data: camelcaseKeys(response.data.clients, { deep: true })
		};
	}

	public static async listScheduledToday(
		limit: number,
		offset: number
	): Promise<IPaginatedResponse<IUser>> {
		const response = await axios.get('/clients/scheduled_today', {
			params: {
				limit,
				offset,
				scheduled_today: true
			}
		});

		return {
			total: response.data.length,
			data: camelcaseKeys(response.data, { deep: true })
		};
	}
}
