import { NavLink } from 'react-router-dom';

import {
	hipaaLinks,
	officeSafeLinks,
	oshaLinks,
	pciLinks,
	settingsLinks
} from './navLinks';
import SignOut from 'shared/components/signOut';

export const hipaaAccordion = [
	{
		title: 'HIPAA',
		component: (
			<>
				{hipaaLinks.map((item, index) => (
					<div key={index}>
						<NavLink to={item.route}>
							<div>
								<span>{item.icon}</span>
								<span>{item.label}</span>
							</div>
						</NavLink>
					</div>
				))}
			</>
		)
	}
];

export const oshaAccordion = [
	{
		title: 'OSHA',
		component: (
			<>
				{oshaLinks.map((item, index) => (
					<div key={index}>
						<NavLink to={item.route}>
							<div>
								<span>{item.icon}</span>
								<span>{item.label}</span>
							</div>
						</NavLink>
					</div>
				))}
			</>
		)
	}
];

export const pciAccordion = [
	{
		title: 'PCI',
		component: (
			<>
				{pciLinks.map((item, index) => (
					<div key={index}>
						{!item.outer ? (
							<NavLink to={item.route}>
								<div>
									<span>{item.icon}</span>
									<span>{item.label}</span>
								</div>
							</NavLink>
						) : (
							<a href={item.route} rel="noreferrer" target="_blank">
								<div>
									<span>{item.icon}</span>
									<span>{item.label}</span>
								</div>
							</a>
						)}
					</div>
				))}
			</>
		)
	}
];

export const officeSafeAccordion = [
	{
		title: 'OfficeSafe University',
		component: (
			<>
				{officeSafeLinks.map((item, index) => (
					<div key={index}>
						<NavLink to={item.route}>
							<div>
								<span>{item.icon}</span>
								<span>{item.label}</span>
							</div>
							{item.warningIcon}
						</NavLink>
					</div>
				))}
			</>
		)
	}
];

export const settingsAccordion = [
	{
		title: 'Settings',
		component: (
			<>
				{settingsLinks.map((item, index) => (
					<div key={index}>
						<NavLink to={item.route}>
							<div>
								<span>{item.icon}</span>
								<span>{item.label}</span>
							</div>
							{item.warningIcon}
						</NavLink>
					</div>
				))}
				<SignOut portal="userPortal" />
			</>
		)
	}
];
