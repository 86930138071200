import { PropsWithChildren, ReactElement } from 'react';

import { adminPortalStyles } from './adminPortalStyles';
import FormActions from 'shared/components/formComponents/formActions';
import FormTitle from 'shared/components/pageSubtitle';
import { handleClasses } from 'shared/utils/handleClasses';
import SubmitStatus from 'shared/components/formComponents/submitStatus';
import { userPortalStyles } from './userPortalStyles';

interface IFormProps {
	onClickCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	onClickReset?: (event: React.MouseEvent<HTMLInputElement>) => void;
	onSubmit?: React.FormEventHandler<HTMLFormElement>;
	portal: 'adminPortal' | 'userPortal';
	description?: string | ReactElement;
	formTitleCustomeClass?: string;
	submitButtonValue?: string;
	isSubmitDisabled?: boolean;
	hasSubmitButton?: boolean;
	hasCancelButton?: boolean;
	hasFormActions?: boolean;
	hasClearButton?: boolean;
	successMessage?: string;
	customClass?: string;
	pageTitle?: string;
}

const Form = ({
	hasSubmitButton = true,
	hasCancelButton = true,
	hasFormActions = true,
	formTitleCustomeClass,
	submitButtonValue,
	isSubmitDisabled,
	successMessage,
	hasClearButton,
	onClickCancel,
	onClickReset,
	description,
	customClass,
	pageTitle,
	onSubmit,
	children,
	portal
}: PropsWithChildren<IFormProps>) => {
	const classes = handleClasses(portal, adminPortalStyles(), userPortalStyles());

	return (
		<form onSubmit={onSubmit} className={`${classes.formWrapper} ${customClass}`}>
			{pageTitle && (
				<FormTitle
					customClass={formTitleCustomeClass}
					pageSubtitle={pageTitle}
					portal={portal}
				/>
			)}

			{description && <p className={classes.description}>{description}</p>}

			{children}

			{successMessage && (
				<SubmitStatus submitStatusMessage={successMessage} isSuccess />
			)}

			{hasFormActions && (
				<div className={classes.formActions}>
					<FormActions
						submitButtonValue={submitButtonValue}
						isSubmitDisabled={isSubmitDisabled}
						hasCancelButton={hasCancelButton}
						hasSubmitButton={hasSubmitButton}
						hasClearButton={hasClearButton}
						onClickCancel={onClickCancel}
						onClickReset={onClickReset}
					/>
				</div>
			)}
		</form>
	);
};

export default Form;
