import { createSlice } from '@reduxjs/toolkit';

import IUserQuiz from 'shared/models/UserQuiz';
import { listQuizzes } from './actions';

interface IState {
	quizzes: IUserQuiz[];
	quizLoading: boolean;
}

const initialState: IState = {
	quizzes: [],
	quizLoading: false
};

export const quizSlice = createSlice({
	name: 'quiz',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(listQuizzes.fulfilled, (state, action) => {
				state.quizzes = action.payload;
				state.quizLoading = false;
			})
			.addCase(listQuizzes.pending, (state) => {
				state.quizLoading = true;
			});
	}
});

export const quizReducer = quizSlice.reducer;
