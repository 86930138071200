import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { findById } from './actions';
import IBaseSale from 'shared/models/BaseSale';
import IBusinessAssociateSale from 'shared/models/BusinessAssociateSale';
import IClientLocation from 'shared/models/ClientLocation';
import IEvaluation from 'shared/models/Evaluation';
import IFinancialInstitutionSale from 'shared/models/FinancialInstitutionSale';
import IMerchantApplication from 'shared/models/MerchantApplication';
import IOrganization from 'shared/models/Organization';
import IReferral from 'shared/models/Referral';
import IUser from 'shared/models/User';

interface IState {
	basicInformation: IOrganization | null;
	users: IUser[];
	locations: IClientLocation[];
	financialInstitutionSales: IFinancialInstitutionSale[];
	businessAssociateSales: IBusinessAssociateSale[];
	merchantApplications: IMerchantApplication[];
	evaluations: IEvaluation[];
	referrals: IReferral[];
	sale: IBaseSale | null;
}

const initialState: IState = {
	basicInformation: null,
	users: [],
	locations: [],
	financialInstitutionSales: [],
	businessAssociateSales: [],
	merchantApplications: [],
	evaluations: [],
	referrals: [],
	sale: null
};

export const organizationSlice = createSlice({
	name: 'organization',
	initialState,
	reducers: {
		setBasicInformation: (state, action: PayloadAction<IOrganization>) => {
			state.basicInformation = action.payload;
		},
		setOrganizationUsers: (state, action: PayloadAction<IUser[]>) => {
			state.users = action.payload;
		},
		restoreState: (state) => {
			state.basicInformation = initialState.basicInformation;
			state.users = initialState.users;
			state.locations = initialState.locations;
			state.financialInstitutionSales = initialState.financialInstitutionSales;
			state.businessAssociateSales = initialState.businessAssociateSales;
			state.merchantApplications = initialState.merchantApplications;
			state.evaluations = initialState.evaluations;
			state.referrals = initialState.referrals;
			state.sale = initialState.sale;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(findById.fulfilled, (state, action) => {
			state.basicInformation = action.payload.organization;
			state.locations = action.payload.locations;
			state.financialInstitutionSales = action.payload.financialInstitutionSales;
			state.businessAssociateSales = action.payload.businessAssociateSales;
			state.merchantApplications = action.payload.merchantApplications;
			state.evaluations = action.payload.evaluations;
			state.referrals = action.payload.referrals;
			state.sale = action.payload.sale;
		});
	}
});

export const { restoreState, setBasicInformation, setOrganizationUsers } =
	organizationSlice.actions;

export const organizationReducer = organizationSlice.reducer;
