import { AppState } from 'shared/store';

export const adminSelector = (state: AppState) => state.auth.admin;
export const userSelector = (state: AppState) => state.auth.user;
export const errorSelector = (state: AppState) => state.auth.errorMessage;
export const loadingSelector = (state: AppState) => state.auth.loginLoading;
export const authEditableSelector = (state: AppState) => state.auth.isEditable;
export const userLoading = (state: AppState) => state.auth.userLoading;
export const fetchingCurrentUserSelector = (state: AppState) =>
	state.auth.fetchingCurrentUser;
