import { useSelector } from 'react-redux';

import { ComplianceStatus } from 'shared/models/User';
import { CourseCategory } from 'shared/models/Course';
import { userSelector } from 'shared/store';
import WarningIcon from 'user/components/warningIcon';

interface ITrainingWarningIconProps {
	category: CourseCategory;
}

const TrainingWarningIcon = ({ category }: ITrainingWarningIconProps) => {
	const user = useSelector(userSelector)!;

	const moduleStatuses = {
		[CourseCategory.Hipaa]: user.hipaaCompliance,
		[CourseCategory.Osha]: user.oshaCompliance
	};

	const moduleStatus = moduleStatuses[category];
	const isIncomplete = moduleStatus === ComplianceStatus.Incomplete;
	const isExpireSoon = moduleStatus === ComplianceStatus.ExpiresSoon;

	return (
		<>
			{isIncomplete && <WarningIcon color="red" />}
			{isExpireSoon && <WarningIcon color="yellow" />}
		</>
	);
};

export default TrainingWarningIcon;
