import { createStyles, makeStyles } from '@material-ui/core';

import { ICustomTheme } from 'admin/styles/theme';

export const adminPortalStyles = makeStyles((theme: ICustomTheme) =>
	createStyles({
		accordionPanel: {
			border: `1px solid ${theme.colors.silver}`,
			boxShadow: 'unset !important',
			margin: '0 0 8px 0',
			'&:before': {
				backgroundColor: 'transparent !important'
			},
			'&.Mui-expanded': {
				margin: '0 0 8px 0 !important'
			}
		},
		accordionSummary: {
			background: `${theme.colors.lightBlue} !important`,
			width: '100%',
			padding: '0 15px',
			maxHeight: '65px !important',
			minHeight: '65px !important',
			'& p': {
				display: 'flex',
				alignItems: 'center',
				fontSize: '18px',
				'& span': {
					display: 'flex',
					alignItems: 'center',
					marginRight: '30px'
				}
			}
		},
		centeredAccordionSummary: {
			'& .MuiAccordionSummary-content': {
				justifyContent: 'center'
			}
		},
		smallAccordionSummary: {
			maxHeight: '35px !important',
			minHeight: '35px !important'
		},
		alwaysExpanded: {
			cursor: 'default !important'
		}
	})
);
