import { AppState } from 'shared/store';

export const basicInformationSelector = (state: AppState) =>
	state.organization.basicInformation;
export const usersSelector = (state: AppState) => state.organization.users;
export const locationsSelector = (state: AppState) => state.organization.locations;
export const financialInstitutionSalesSelector = (state: AppState) =>
	state.organization.financialInstitutionSales;
export const businessAssociateSalesSelector = (state: AppState) =>
	state.organization.businessAssociateSales;
export const merchantApplicationsSelector = (state: AppState) =>
	state.organization.merchantApplications;
export const evaluationsSelector = (state: AppState) =>
	state.organization.evaluations;
export const referralsSelector = (state: AppState) => state.organization.referrals;
export const saleSelector = (state: AppState) => state.organization.sale;
