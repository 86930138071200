export enum QuizStatus {
	Incomplete = 'Incomplete',
	Complete = 'Complete',
	ExpiresSoon = 'Expires soon',
	Expired = 'Expired'
}

export default interface IUserQuiz {
	id: string;
	name: string;
	passingGrade: number;
	imagePath: string;
	userQuizId?: string;
	status: QuizStatus;
	expirationDate?: string;
	finishedAt?: string;
	certificateLink?: string;
}
