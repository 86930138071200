import { createStyles, makeStyles } from '@material-ui/core';

export const enterprisePortalStyles = makeStyles(() =>
	createStyles({
		pageSubtitle: {
			fontWeight: 600,
			fontSize: '24px',
			color: '#242F40'
		}
	})
);
