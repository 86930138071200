import { Suspense, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { useRoutes } from 'react-router-dom';

import { listCourses, listQuizzes } from 'user/store';
import Footer from 'shared/components/footer';
import Header from 'shared/components/header';
import Loader from 'shared/components/loader';
import routes from './routes';
import Sidebar from 'user/components/sidebar';
import { styles } from './styles';
import User from 'shared/models/User';

interface IPrivateRoutesProps {
	user: User;
}

const PrivateRoutes = ({ user }: IPrivateRoutesProps) => {
	const matchesMediaQuery = useMediaQuery('(min-width:770px)');
	const dispatch = useDispatch();
	const classes = styles();

	const authorizedRoutes = routes.filter((route) => {
		return !route.userTypes || route.userTypes.includes(user.type);
	});

	const elements = useRoutes(authorizedRoutes);

	useEffect(() => {
		dispatch(listCourses(user.id));
		dispatch(listQuizzes(user.id));
	}, []);

	return (
		<>
			<Header portal="userPortal" />
			<div className={classes.contentWrapper}>
				{matchesMediaQuery && <Sidebar />}
				<div className={classes.appContent}>
					<div className={classes.suspenseWrapper}>
						<Suspense fallback={<Loader />}>{elements}</Suspense>
					</div>
					<Footer />
				</div>
			</div>
		</>
	);
};

export default PrivateRoutes;
