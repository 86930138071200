import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';

import IQuestionCategory from 'shared/models/QuestionCategory';

export default class QuestionCategoryService {
	public static async list(): Promise<IQuestionCategory[]> {
		const response = await axios.get('/question_categories/all_categories');
		return camelcaseKeys(response.data, { deep: true });
	}
}
