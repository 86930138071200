import { styles } from './styles';

interface ISubmitStatusProps {
	submitStatusMessage: string;
	isGenerating?: boolean;
	customClass?: string;
	isSuccess?: boolean;
}

const SubmitStatus = ({
	submitStatusMessage,
	isGenerating,
	customClass,
	isSuccess
}: ISubmitStatusProps) => {
	const classes = styles();

	return (
		<div
			className={`${isGenerating && classes.generatingMessage}
			${isSuccess ? classes.green : classes.red}
			${classes.successSubmitWrapper}
			${customClass}`}
		>
			<p>{submitStatusMessage}</p>
		</div>
	);
};

export default SubmitStatus;
