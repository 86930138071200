const organizationsUrl = '/organizations';
const merchantApplicationUrl = '/merchantApplications';
const locationUrl = '/locations';

export const ORGANIZATIONS = organizationsUrl;

export const MERCHANT_APPLICATION = merchantApplicationUrl;

export const LOCATIONS = locationUrl;

export const NEW_ORGANIZATION = `${organizationsUrl}/new`;

export const VIEW_ORGANIZATION = `${organizationsUrl}/:id`;
export const VIEW_ORGANIZATION_USER = `${VIEW_ORGANIZATION}/users/:userId`;

export const SALES_SUMMARY = 'salesSummary';
export const VIEW_SALES_SUMMARY = `${VIEW_ORGANIZATION}/${SALES_SUMMARY}/:salesId`;
export const NEW_SALES_SUMMARY = `${VIEW_ORGANIZATION}/${SALES_SUMMARY}/new`;
export const EDIT_SALES_SUMMARY = `${VIEW_ORGANIZATION}/${SALES_SUMMARY}/:salesId/edit`;

export const ADD_LOCATION = `${VIEW_ORGANIZATION}/locations/new`;
export const EDIT_LOCATION = `${VIEW_ORGANIZATION}/locations/:locationId`;

export const VIEW_PAYMENT = `${VIEW_ORGANIZATION}/merchantApplications/:id`;
