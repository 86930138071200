import { styles } from './styles';

interface ILabelProps {
	uncontrollableWidth?: boolean;
	label?: string | JSX.Element;
	isPreviousLabel?: boolean;
	isAfterLabel?: boolean;
	noWrapLabel?: boolean;
	isUppercase?: boolean;
	customClass?: string;
	isDisabled?: boolean;
	isBold?: boolean;
	color?: 'darkGray' | 'lightBlue';
}

const Label = ({
	uncontrollableWidth = true,
	noWrapLabel = true,
	isDisabled = false,
	isPreviousLabel,
	isBold = false,
	color = 'darkGray',
	isAfterLabel,
	isUppercase,
	customClass,
	label
}: ILabelProps) => {
	const classes = styles();

	const colorClasses = {
		darkGray: classes.darkGrayLabel,
		lightBlue: classes.lightBlueLabel
	};

	return (
		<label
			className={`${classes.label}
			${uncontrollableWidth && classes.uncontrollableWidth}
			${isPreviousLabel && classes.previousLabel}
			${isUppercase && classes.uppercaseLabel}
			${isDisabled && classes.disabledLabel}
			${isAfterLabel && classes.afterLabel}
			${noWrapLabel && classes.noWrapLabel}
			${isBold && classes.boldLabel}
			${colorClasses[color]}
			${customClass}`}
		>
			{label}
		</label>
	);
};

export default Label;
