import { Tooltip } from '@material-ui/core';

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useNavigate } from 'react-router-dom';

import { USERS } from 'enterprise/routes/pathNames';

import { styles } from './styles';

interface ICustomTooltipProps {
	value: JSX.Element | string;
	isClickable?: boolean;
	isHighlight?: boolean;
	isTextWrap?: boolean;
	isLocation?: boolean;
	searchValue?: string;
	className?: string;
	isBold?: boolean;
}

const CustomTooltip = ({
	isHighlight = false,
	searchValue,
	isClickable,
	isTextWrap,
	isLocation,
	className,
	isBold,
	value
}: ICustomTooltipProps) => {
	const navigate = useNavigate();
	const classes = styles();

	const matches = match(value ? (value as string) : '', searchValue || '', {
		insideWords: true
	});

	const parts = parse(value as string, matches);

	const getSearchParam = () => {
		navigate({ pathname: USERS, search: encodeURIComponent(value as string) });
	};

	return (
		<Tooltip placement="bottom-start" title={value}>
			<p
				onClick={isClickable ? getSearchParam : () => {}}
				className={`
					${classes.tooltip} ${isTextWrap ? 'textWrap' : ''}
					${isLocation ? 'location' : ''}
					${isBold ? 'bold' : ''}
					${className || ''}
				`}
			>
				{isHighlight
					? parts?.map((part, index) => (
							<span className={part.highlight ? 'highlight' : ''} key={index}>
								{part.text}
							</span>
					  ))
					: value}
			</p>
		</Tooltip>
	);
};

export default CustomTooltip;
