import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';

import IOrganization from 'shared/models/Organization';
import IPaginatedResponse from 'shared/services/PaginatedResponse';

interface IEnterpriseServiceProps {
	order?: 'asc' | 'desc';
	paginated?: boolean;
	pageSize?: number;
	query?: string;
	sort?: string;
	page?: number;
	locationFilter?: string[];
}

export type UsresSortField =
	| 'hipaaPercentage'
	| 'oshaPercentage'
	| 'createdAt'
	| 'firstName'
	| 'lastName'
	| 'email';

export type LocationsSortField = 'name' | 'hipaaPercentage' | 'oshaPercentage';

export default class EnterpriseService {
	public static async getOrganizations(
		props: IEnterpriseServiceProps
	): Promise<IPaginatedResponse<IOrganization>> {
		const url = '/enterprise_admin/locations';

		const config = {
			params: {
				sort: this.mapLocationsSortField(props.sort as LocationsSortField),
				page_size: props.pageSize,
				query: props.query,
				order: props.order,
				page: props.page,
				paginated: true
			}
		};

		const response = await axios.get(url, config);

		return {
			total: response.data['total_organizations'],
			data: camelcaseKeys(response.data['organizations'], { deep: true })
		};
	}

	public static async getUsers(props: IEnterpriseServiceProps): Promise<any> {
		const url = '/enterprise_admin/users';

		const config = {
			params: {
				sort: this.mapUsersSortField(props.sort as UsresSortField),
				page_size: props.pageSize,
				query: props.query,
				order: props.order,
				page: props.page,
				paginated: true,
				location_filter: props.locationFilter
			}
		};

		const response = await axios.get(url, config);

		return {
			total: response.data.total_users,
			data: camelcaseKeys(response.data.users, { deep: true })
		};
	}

	public static async getLocations(searchValue: string): Promise<any> {
		const url = '/enterprise_admin/location-filter';

		const config = {
			params: {
				query: searchValue
			}
		};

		const response = await axios.get(url, config);

		return response?.data;
	}

	public static exportUsers(enterpriseId: string) {
		window.open(
			`${this.baseUrl}/enterprise_admin/users-compliance/${enterpriseId}`,
			'_blank'
		);
	}

	private static baseUrl = `${axios.defaults.baseURL}`;

	private static mapLocationsSortField(sort: LocationsSortField): string {
		const sortFields = {
			hipaaPercentage: 'hipaa_percentage',
			oshaPercentage: 'osha_percentage',
			name: 'name'
		};

		return sortFields[sort];
	}

	private static mapUsersSortField(sort: UsresSortField): string {
		const sortFields = {
			organizationName: 'organization_name',
			hipaaPercentage: 'hipaa_percentage',
			oshaPercentage: 'osha_percentage',
			createdAt: 'created_at',
			firstName: 'first_name',
			lastName: 'last_name',
			email: 'email'
		};

		return sortFields[sort];
	}

	public static async getPendoDataToSend(host: string): Promise<any> {
		const url = '/analytics/initialize';

		const config = {
			params: {
				host
			}
		};

		const response = await axios.get(url, config);

		return response?.data;
	}

	public static async getEnterpriseStats(): Promise<any> {
		const url = '/enterprise_admin/stats';

		const response = await axios.get(url);

		return response?.data;
	}
}
