import { createAsyncThunk } from '@reduxjs/toolkit';

import ClientService, { ISearchProps } from 'admin/services/ClientService';
import IOrganization from 'shared/models/Organization';
import IPaginatedResponse from 'shared/services/PaginatedResponse';

export const getExportedLeads = createAsyncThunk<
	IPaginatedResponse<IOrganization>,
	ISearchProps
>(
	'admin/exportedLeads',
	async ({ limit, offset, status, order }) =>
		await ClientService.list({ limit, offset, status, order })
);
