import { AppState } from 'shared/store';

// OSHA
export const oshaCoursesSelector = (state: AppState) => state.courses.oshaCourses;
export const oshaCoursesLoadingSelector = (state: AppState) =>
	state.courses.oshaCoursesLoading;
export const oshaSuccessMessageSelector = (state: AppState) =>
	state.courses.oshaSuccessMessage;

// HIPAA
export const hipaaCoursesSelector = (state: AppState) => state.courses.hipaaCourses;
export const hipaaCoursesLoadingSelector = (state: AppState) =>
	state.courses.hipaaCoursesLoading;
export const hipaaSuccessMessageSelector = (state: AppState) =>
	state.courses.hipaaSuccessMessage;
