import { createStyles, makeStyles } from '@material-ui/core';

import { ICustomTheme } from 'admin/styles/theme';

export const styles = makeStyles((theme: ICustomTheme) =>
	createStyles({
		sidebarWrapper: {
			padding: '20px 0',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'auto',
			minHeight: '100%',
			overflowX: 'hidden',
			overflowY: 'auto',
			backgroundColor: theme.colors.lightBlue,
			borderRight: `1px solid ${theme.colors.gray}`,
			minWidth: '205px',
			maxWidth: '205px'
		},
		sidebarLink: {
			display: 'flex',
			alignItems: 'center',
			padding: '8px 20px',
			color: theme.colors.text,
			fontSize: '14px',
			textDecoration: 'none',
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: theme.colors.gray,
				color: theme.colors.text,
				textDecoration: 'none'
			},
			'&.active': { backgroundColor: theme.colors.gray, fontWeight: 'bold' }
		},
		settingsTitle: {
			padding: '8px 20px',
			fontSize: '13px',
			marginTop: '20px',
			color: theme.colors.darkGray
		},
		sidebarIcon: {
			width: '17px',
			height: '17px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			'& svg': {
				width: '100%',
				height: '100%'
			}
		},
		sidebarLabel: {
			marginLeft: '10px'
		}
	})
);
