import { PriorityHighRounded } from '@material-ui/icons';

import { styles } from './styles';

interface IWarningIconProps {
	color: 'red' | 'yellow';
}

const WarningIcon = ({ color }: IWarningIconProps) => {
	const classes = styles();

	const colors = {
		red: classes.redIcon,
		yellow: classes.yellowIcon
	};

	return (
		<>
			<div className={`${classes.warningIconWrapper} ${colors[color]}`}>
				<PriorityHighRounded />
			</div>
		</>
	);
};

export default WarningIcon;
