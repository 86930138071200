import { createStyles, makeStyles } from '@material-ui/core';

export const styles = makeStyles(() =>
	createStyles({
		globalSearchForm: {
			margin: 'unset !important',
			minWidth: 'unset !important',
			maxWidth: 'unset !important',
			marginRight: '20px !important',
			'& > div': {
				margin: '0'
			}
		},
		searchButton: {
			all: 'unset',
			cursor: 'pointer',
			'&:disabled': {
				cursor: 'not-allowed'
			}
		}
	})
);
