import { createStyles, makeStyles } from '@material-ui/core';

import { ICustomTheme } from 'admin/styles/theme';

export const styles = makeStyles((theme: ICustomTheme) =>
	createStyles({
		contentWrapper: {
			display: 'flex',
			height: 'calc(100% - 80px)'
		},
		appContent: {
			flex: 1,
			minHeight: '100%',
			overflow: 'auto',
			background: theme.colors.white
		},
		suspenseWrapper: {
			flex: 1,
			overflow: 'auto',
			backgroundColor: '#fff',
			minHeight: 'calc(100% - 60px)',
			padding: '16px 30px'
		}
	})
);
