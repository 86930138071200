import { createAsyncThunk } from '@reduxjs/toolkit';

import IQuestionCategory from 'shared/models/QuestionCategory';
import ISection from 'shared/models/Section';
import QuestionCategoryService from 'admin/services/QuestionCategoryService';
import RiskAssessmentService from 'admin/services/RiskAssessmentService';

export const listLongQuestions = createAsyncThunk<ISection[], string | undefined>(
	'longRiskAssessment/listQuestions',
	async (resellerId) => {
		return await RiskAssessmentService.listLongQuestions(resellerId);
	}
);

export const listQuestionCategories = createAsyncThunk<IQuestionCategory[]>(
	'longRiskAssessment/listQuestionCategories',
	async () => {
		return await QuestionCategoryService.list();
	}
);
