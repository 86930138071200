import { useDispatch, useSelector } from 'react-redux';

import { Environment, userSelector } from 'shared/store';
import AmazingEnterpriseHealthIcon from 'shared/assets/icons/amazing-enterprise-health.svg';
import BlueGroupHealthCompanyIcon from 'shared/assets/icons/blueGroup-health-company.svg';
import config from 'shared/config';
import CustomTooltip from 'shared/components/tooltip';
import EnterpriseNavigationArrowIcon from 'shared/assets/icons/enterprise-navigation-arrow.svg';
import Image from 'shared/components/image';
import { logout } from 'shared/store';
import LogoutIcon from 'shared/assets/icons/logout.svg';

import { styles } from './styles';

const EnterpriseNavigation = () => {
	const classes = styles();
	const dispatch = useDispatch();
	const user = useSelector(userSelector);
	const enterpriseName = user?.enterprise?.name;
	const organizationName = user?.organization?.name;

	const onLogout = () => {
		dispatch(logout());
	};

	const redirectToLocation = () => {
		let redirctingAllowed =
			config.environment && Object.values(Environment).includes(config.environment);

		// add your localhost route in the else, if you want to tested local
		const redirectUrl = redirctingAllowed
			? config.apiUrl?.replace('api', '#/myaccount')
			: '';

		window.open(redirectUrl, '_blank');
	};

	return (
		<div className={classes.enterpriseNavigationWrapper}>
			<div>
				<div>
					<Image src={AmazingEnterpriseHealthIcon} />
					<CustomTooltip value={<p>{enterpriseName ?? ''}</p>} />
				</div>
				<button disabled>Manage My Enterprise</button>
			</div>

			<div>
				<div>
					<Image src={EnterpriseNavigationArrowIcon} />
					<Image src={BlueGroupHealthCompanyIcon} />
					<CustomTooltip value={<p>{organizationName ?? ''}</p>} />
				</div>
				<button onClick={redirectToLocation}>Manage My Location</button>
			</div>

			<div onClick={onLogout}>
				<Image src={LogoutIcon} />
				<p>Logout</p>
			</div>
		</div>
	);
};

export default EnterpriseNavigation;
