import { createStyles, makeStyles } from '@material-ui/core';

export const styles = makeStyles(() =>
	createStyles({
		sidebarWrapper: {
			display: 'flex',
			flexDirection: 'column',
			overflow: 'auto',
			minHeight: '100%',
			overflowX: 'hidden',
			overflowY: 'auto',
			backgroundColor: '#4A91E2',
			minWidth: '245px',
			maxWidth: '245px',
			'& a': {
				textDecoration: 'none',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				padding: '9px 16px',
				'& > div': {
					display: 'flex',
					alignItems: 'center'
				},
				'& span': {
					'&:first-of-type': {
						display: 'flex',
						alignItems: 'center',
						marginRight: '8px',
						'& > svg': {
							color: '#fff',
							fontSize: '16px'
						}
					},
					'&:last-of-type': {
						display: 'flex',
						alignItems: 'center',
						color: '#fff',
						fontSize: '12px'
					}
				},
				'&.active': {
					backgroundColor: '#3B79BF'
				},
				'&:hover': {
					transition: 'all 0.2s ease-in-out',
					backgroundColor: '#3B79BF'
				}
			}
		},
		sidebarAccordions: {
			border: 'unset',
			borderBottom: '1px solid #60AAFF',
			margin: '0',
			'&.Mui-expanded': {
				margin: '0 !important'
			},
			'& .MuiAccordionSummary-root': {
				'& p': {
					fontSize: '14px',
					fontWeight: 900
				}
			},
			'& .MuiCollapse-root': {
				backgroundColor: '#4A91E2'
			},
			'& .MuiAccordionDetails-root': {
				padding: '0'
			}
		}
	})
);
