import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';

import IQuestion, {
	IDetailedAction,
	IDetailedRequirement,
	TypeOfAnswer
} from 'shared/models/Question';
import IEvaluation from 'shared/models/Evaluation';
import IPreliminaryQuestion from 'shared/models/PreliminaryQuestion';
import ISection from 'shared/models/Section';

export interface IPreliminaryQuestionProps {
	text: string;
	selected: boolean;
	countsForScore: boolean;
	possibleAnswers?: string[];
	typeOfAnswer: TypeOfAnswer;
	resellerEmail?: string;
}

export interface ICreateQuestionProps {
	text: string;
	selected: boolean;
	sectionId: string;
	typeOfAnswer: TypeOfAnswer;
	countsForScore: boolean;
	possibleAnswers: string[];
	requirements: string;
	threats: string;
	recommendedActions: string[];
	categoryId: string;
	isReseller: boolean;
}

export interface IUpdateQuestionProps {
	text: string;
	selected: boolean;
	sectionId: string;
	typeOfAnswer: TypeOfAnswer;
	countsForScore: boolean;
	possibleAnswers: { label: string }[];
	requirements: IDetailedRequirement[];
	threats: IDetailedAction[];
	recommendedActions: IDetailedAction[];
	categoryId: string;
}

export default class RiskAssessmentService {
	public static async findById(id: string): Promise<IEvaluation> {
		const response = await axios.get(`/evaluations/${id}`);
		return camelcaseKeys(response.data, { deep: true });
	}

	public static async listShortQuestions(
		resellerId?: string
	): Promise<IPreliminaryQuestion[]> {
		const route = !resellerId ? 'questions' : 'reseller_questions_by_id';

		const response = await axios.get(`/preliminary_evaluations/${route}`, {
			params: { reseller_id: resellerId }
		});

		const questions: IPreliminaryQuestion[] = camelcaseKeys(response.data, {
			deep: true
		});

		return questions.map((question) => this.prepareShortQuestion(question));
	}

	public static async listLongQuestions(resellerId?: string): Promise<ISection[]> {
		const route = !resellerId ? 'questions' : 'reseller-sections';

		const response = await axios.get(`/evaluations/${route}`, {
			params: { id: resellerId }
		});

		const sections: ISection[] = camelcaseKeys(response.data, { deep: true });
		return sections.map((section) => {
			return {
				...section,
				questions: section.questions
					.map((question) => this.prepareLongQuestion(question))
					.sort((a, b) => a.id.localeCompare(b.id))
			};
		});
	}

	public static async createShortQuestion(
		props: IPreliminaryQuestionProps
	): Promise<IPreliminaryQuestion> {
		const route = !props.resellerEmail ? 'add_default_question' : 'add_question';

		const response = await axios.put(`/preliminary_evaluations/${route}`, {
			...this.mapShortPropsToRequest(props)
		});

		const question = camelcaseKeys(response.data, { deep: true });
		return this.prepareShortQuestion(question);
	}

	public static async updateShortQuestion(
		id: string,
		props: IPreliminaryQuestionProps
	): Promise<IPreliminaryQuestion> {
		const route = !props.resellerEmail
			? 'update_default_question'
			: 'update_question';

		const response = await axios.put(`/preliminary_evaluations/${id}/${route}`, {
			...this.mapShortPropsToRequest(props)
		});

		const question = camelcaseKeys(response.data, { deep: true });
		return this.prepareShortQuestion(question);
	}

	public static async createLongQuestion(
		props: ICreateQuestionProps
	): Promise<IQuestion> {
		const route = !props.isReseller
			? 'add-default-question'
			: 'add-reseller-question';

		const response = await axios.put(`/evaluations/${route}`, {
			...this.mapLongCreatePropsToRequest(props)
		});

		return camelcaseKeys(response.data, { deep: true });
	}

	public static async updateLongQuestion(
		id: string,
		props: IUpdateQuestionProps
	): Promise<IQuestion> {
		const response = await axios.put(`/evaluations/${id}/update_default_question`, {
			...this.mapLongUpdatePropsToRequest(props)
		});

		return camelcaseKeys(response.data, { deep: true });
	}

	private static prepareShortQuestion(
		question: IPreliminaryQuestion
	): IPreliminaryQuestion {
		return {
			...question,
			typeOfAnswer: question.typeOfAnswer || TypeOfAnswer.YesNo
		};
	}

	private static prepareLongQuestion(question: IQuestion): IQuestion {
		const questionOptions = question.requiredFields?.[0]?.questionOptions || [];
		const possibleAnswers = questionOptions.map((option) => option.label);
		return {
			...question,
			categoryId: question.questionCategoryIds?.[0] || '',
			possibleAnswers,
			typeOfAnswer: question.typeOfAnswer || TypeOfAnswer.Radio,
			requirements: question.requirementsAsJson[0]?.text || '',
			threats: question.threatsAsJson[0]?.description || ''
		};
	}

	private static mapShortPropsToRequest(props: IPreliminaryQuestionProps): object {
		const reseller = !props.resellerEmail
			? { reseller_id: 'default' }
			: { reseller_email: props.resellerEmail };

		return {
			text: props.text,
			selected: props.selected,
			type_of_answer: props.typeOfAnswer,
			possible_answers: props.possibleAnswers,
			counts_for_score: props.countsForScore,
			...reseller
		};
	}

	private static mapLongCreatePropsToRequest(props: ICreateQuestionProps): object {
		return {
			text: props.text,
			selected: props.selected,
			section_id: props.sectionId,
			type_of_answer: props.typeOfAnswer || TypeOfAnswer.Radio,
			counts_for_score: props.countsForScore,
			possible_answers: props.possibleAnswers,
			requirements: props.requirements,
			threats: props.threats,
			recommended_actions: props.recommendedActions,
			question_category_ids: [props.categoryId]
		};
	}

	private static mapLongUpdatePropsToRequest(props: IUpdateQuestionProps): object {
		return {
			text: props.text,
			selected: props.selected,
			section_id: props.sectionId,
			type_of_answer: props.typeOfAnswer || TypeOfAnswer.Radio,
			counts_for_score: props.countsForScore,
			possible_answers: props.possibleAnswers,
			requirements_ids: props.requirements,
			threats_ids: props.threats,
			action_ids: props.recommendedActions,
			question_category_ids: [props.categoryId]
		};
	}
}
