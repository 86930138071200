const merchantUrl = '/merchantTemplates';

export const MERCHANT_TEMPLATES = merchantUrl;

export const TRANSACTION = `${merchantUrl}/transaction`;
export const NEW_TRANSACTION = `${TRANSACTION}/new`;
export const VIEW_TRANSACTION = `${TRANSACTION}/:id`;

export const SALES = `${merchantUrl}/sales`;
export const NEW_SALES = `${SALES}/new`;
export const VIEW_SALES = `${SALES}/:id`;
