import { createAsyncThunk } from '@reduxjs/toolkit';

import OrganizationService, {
	IFindByIdResponse
} from 'admin/services/OrganizationService';

export const findById = createAsyncThunk<IFindByIdResponse, string>(
	'organization/findById',
	async (id) => {
		return await OrganizationService.findById(id);
	}
);
