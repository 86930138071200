import { CSSProperties } from 'react';

interface IImage {
	onClick?: (event: any) => void;
	style?: CSSProperties;
	className?: string;
	height?: number;
	width?: number;
	alt?: string;
	src: string;
}

const Image = ({
	className,
	alt = '',
	onClick,
	width,
	height,
	style,
	src
}: IImage) => (
	<img
		className={className}
		onClick={onClick}
		height={height}
		style={style}
		width={width}
		alt={alt}
		src={src}
	/>
);

export default Image;
