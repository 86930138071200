import { Suspense } from 'react';

import { useRoutes } from 'react-router-dom';

import Loader from 'shared/components/loader';
import routes from './routes';
import { styles } from './styles';

const PublicRoutes = () => {
	const elements = useRoutes(routes);
	const classes = styles();

	return (
		<>
			<Suspense fallback={<Loader />}>
				<div className={classes.authWrapper}>{elements}</div>
			</Suspense>
		</>
	);
};

export default PublicRoutes;
