import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';

import './index.css';
import 'shared/services/config';
import App from './shared/app/App';
import customTheme from 'admin/styles/theme';
import reportWebVitals from './reportWebVitals';
import store from 'shared/store';

const defaultTheme = createTheme({});

ReactDOM.render(
	<Provider store={store}>
		<ThemeProvider theme={{ ...defaultTheme, ...customTheme }}>
			<CssBaseline />
			<App />
		</ThemeProvider>
	</Provider>,
	document.getElementById('root')
);

reportWebVitals();
