import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';

import { CourseCategory, CourseKey } from 'shared/models/Course';
import IUser from 'shared/models/User';
import IUserCourse from 'shared/models/UserCourse';

export default class UserCourseService {
	public static async list(
		userId: string,
		category?: CourseCategory
	): Promise<IUserCourse[]> {
		const response = await axios.get(`/users/${userId}/courses`, {
			params: { category }
		});

		return camelcaseKeys(response.data, { deep: true });
	}

	public static async submitCertificate(
		userId: string,
		key: CourseKey
	): Promise<IUser> {
		const response = await axios.post('/users/submit-certificate', {
			id: userId,
			form_key: key
		});

		return camelcaseKeys(response.data, { deep: true });
	}
}
