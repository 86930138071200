import { useState } from 'react';

import { ClickAwayListener } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { userLoading, userSelector } from 'shared/store';
import ArrowDown from 'shared/assets/icons/arrow-down.svg';
import Avatar from 'shared/assets/icons/avatar.svg';
import EnterpriseNavigation from '../enterpriseNavigation';
import Image from 'shared/components/image';
import Loader from 'shared/components/loader';

import { styles } from './styles';

const Header = () => {
	const [open, setOpen] = useState(false);
	const user = useSelector(userSelector);
	const classes = styles();
	const isLoading = useSelector(userLoading);
	const handleClick = () => setOpen((prev) => !prev);
	const handleClickAway = () => setOpen(false);

	return (
		<div className={classes.headerWrapper}>
			<p className={classes.organizationName}>
				{!isLoading ? user?.enterprise?.name : null}
			</p>
			{isLoading ? (
				<Loader size={20} customClass={classes.loader} />
			) : (
				<ClickAwayListener onClickAway={handleClickAway}>
					<div className={classes.userInfoWrapper} onClick={handleClick}>
						<div className={classes.userInfo}>
							<Image src={Avatar} />
							<div>
								<p>
									{user?.firstName} {user?.lastName}
								</p>
								<p>{user?.email}</p>
							</div>
						</div>
						<Image
							style={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
							src={ArrowDown}
							className={classes.arrow}
						/>
						<div>{open && <EnterpriseNavigation />}</div>
					</div>
				</ClickAwayListener>
			)}
		</div>
	);
};

export default Header;
