import { createStyles, makeStyles } from '@material-ui/core';

export const styles = makeStyles(() =>
	createStyles({
		asideWrapper: {
			width: '260px',
			backgroundColor: '#fff',
			borderRight: '1px solid #F1F5F9',
			padding: '24px 0',
			boxShadow:
				'0px 10px 15px -3px rgba(150, 167, 200, 0.1), 0px 4px 6px -4px rgba(150, 167, 200, 0.1)',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			overflow: 'hidden',
			'& > div': {
				padding: '0 24px'
			},
			'& div': {
				'& a': {
					display: 'flex',
					alignItems: 'center',
					padding: '14px 18px',
					textDecoration: 'none',
					margin: '0 0 10px 0',
					fontSize: '16px',
					'&.active': {
						backgroundColor: '#3B82F6',
						borderRadius: '8px',
						color: '#fff',
						fontWeight: 600,
						'& .icon': { display: 'none' },
						'& .activeIcon': { display: 'block !important' }
					},
					'&.inactive': {
						fontWeight: 600,
						color: '#6B7280',
						'& .icon': { display: 'block' },
						'& .activeIcon': { display: 'none' }
					},
					'& span': {
						margin: '0 0 0 10px'
					}
				}
			}
		},
		officeSafeLogo: {
			margin: '0 auto 32px auto',
			display: 'flex',
			width: '100%'
		},
		currentUserWrapper: {
			borderTop: '1px solid #E2E8F0',
			paddingTop: '44px !important',
			display: 'flex',
			alignItems: 'center',
			'& div': {
				display: 'flex',
				flexDirection: 'column',
				marginLeft: '8px',
				'& span': {
					fontSize: '14px',
					'&:first-of-type': {
						color: '#374151',
						fontWeight: 700,
						marginBottom: '6px'
					},
					'&:last-of-type': {
						color: '#6B7280',
						fontWeight: 400
					}
				}
			}
		}
	})
);
