import IPreliminaryQuestion from './PreliminaryQuestion';
import IQuestionField from './QuestionField';

export enum TypeOfAnswer {
	Radio = 'radio',
	Dropdown = 'dropdown',
	YesNo = 'yes_no',
	Customized = 'customized'
}

export const readableTypeOfAnswer = {
	[TypeOfAnswer.Radio]: 'Radio Button',
	[TypeOfAnswer.Dropdown]: 'Dropdown',
	[TypeOfAnswer.YesNo]: 'Yes No',
	[TypeOfAnswer.Customized]: 'Customized'
};

export interface IDetailedRequirement {
	id: string;
	text: string;
	createdAt: Date;
	updatedAt: Date;
}

export interface IDetailedAction {
	id: string;
	description: string;
	createdAt: Date;
	updatedAt: Date;
}

export default interface IQuestion extends IPreliminaryQuestion {
	possibleAnswers: string[];
	code: string;
	conditionalAnswer: number;
	conditionalText: string | null;
	number: number;
	requiredFields: IQuestionField[];
	optionalFields?: IQuestionField[];
	sectionId: string;
	version: string;
	weight: number;
	// Category
	categoryId: string;
	questionCategoryIds: string[];
	// Requirements
	questionRequirements: string[];
	requirementsAsJson: IDetailedRequirement[];
	requirements: string;
	// Threats
	questionThreats: string[];
	threatsAsJson: IDetailedAction[];
	threats: string;
	// Recommended Actions
	questionRecommendedActions: string[];
	recommendedActionsAsJson: IDetailedAction[];
}
