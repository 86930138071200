import { createStyles, makeStyles } from '@material-ui/core';

import { ICustomTheme } from 'admin/styles/theme';

export const styles = makeStyles((theme: ICustomTheme) =>
	createStyles({
		label: {
			margin: '0 20px 0 0',
			fontSize: '13px',
			minWidth: '175px',
			maxWidth: '175px'
		},
		noWrapLabel: { whiteSpace: 'nowrap' },
		uncontrollableWidth: {
			minWidth: 'unset',
			maxWidth: 'unset'
		},
		previousLabel: {
			margin: '0 10px 0 0',
			fontSize: '13px'
		},
		afterLabel: {
			margin: '0 0 0 10px',
			fontSize: '13px'
		},
		boldLabel: {
			fontWeight: 700
		},
		uppercaseLabel: {
			textTransform: 'uppercase'
		},
		disabledLabel: {
			opacity: '0.7',
			cursor: 'not-allowed'
		},
		darkGrayLabel: {
			color: theme.colors.darkGray
		},
		lightBlueLabel: {
			color: theme.colors.blue
		}
	})
);
