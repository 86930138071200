import { Suspense } from 'react';

import { useMediaQuery } from '@material-ui/core';
import { useRoutes } from 'react-router-dom';

import Admin from 'shared/models/Admin';
import Footer from 'shared/components/footer';
import Header from 'shared/components/header';
import Loader from 'shared/components/loader';
import routes from './routes';
import Sidebar from 'admin/components/sidebar';
import { styles } from './styles';

interface IPrivateRoutesProps {
	admin: Admin;
}

const PrivateRoutes = ({ admin }: IPrivateRoutesProps) => {
	const matchesMediaQuery = useMediaQuery('(min-width:770px)');
	const classes = styles();

	const authorizedRoutes = routes.filter((route) => {
		return !route.adminTypes || route.adminTypes.includes(admin.type);
	});

	const elements = useRoutes(authorizedRoutes);

	return (
		<>
			<Header portal="adminPortal" />
			<div className={classes.contentWrapper}>
				{matchesMediaQuery && <Sidebar />}
				<div className={classes.appContent}>
					<div className={classes.suspenseWrapper}>
						<Suspense fallback={<Loader customClass={classes.fallbackLoader} />}>
							{elements}
						</Suspense>
					</div>
					<Footer />
				</div>
			</div>
		</>
	);
};

export default PrivateRoutes;
