import { NavLink } from 'react-router-dom';

import Image from '../../../shared/components/image';
import { links } from './navLinks';
import OfficeSafeLogo from 'shared/assets/images/officesafe-logo2x.png';
import { styles } from './styles';

const Sidebar = () => {
	const classes = styles();

	return (
		<aside className={classes.asideWrapper}>
			<div>
				<Image src={OfficeSafeLogo} className={classes.officeSafeLogo} />

				{links.map((item, index) => (
					<div key={index}>
						<NavLink
							to={item.route}
							className={({ isActive }) => (isActive ? 'active' : 'inactive')}
						>
							<img src={item.icon} className="icon" alt="" />
							<img src={item.activeIcon} className="activeIcon" alt="" />
							<span>{item.label}</span>
						</NavLink>
					</div>
				))}
			</div>
		</aside>
	);
};

export default Sidebar;
