import { Fragment } from 'react';

import { useMediaQuery } from '@material-ui/core';

import {
	hipaaAccordion,
	officeSafeAccordion,
	oshaAccordion,
	pciAccordion,
	settingsAccordion
} from './sidebarAccordions';
import CustomAccordion from 'shared/components/formComponents/accordion';
import { styles } from './styles';

const Sidebar = () => {
	const matchesMediaQuery = useMediaQuery('(min-width:770px)');
	const classes = styles();

	const sidebarAccordions = [
		hipaaAccordion,
		oshaAccordion,
		pciAccordion,
		officeSafeAccordion,
		settingsAccordion
	];

	return (
		<aside className={classes.sidebarWrapper}>
			{sidebarAccordions.map((accordionPanel, index) => (
				<Fragment key={index}>
					{matchesMediaQuery ? (
						<CustomAccordion
							customClass={classes.sidebarAccordions}
							accordionPanels={accordionPanel}
							portal="userPortal"
							defaultExpanded
							key={index}
							isSmall
						/>
					) : (
						<>
							{accordionPanel.map((item, index) => (
								<Fragment key={index}>{item.component}</Fragment>
							))}
						</>
					)}
				</Fragment>
			))}
		</aside>
	);
};

export default Sidebar;
