import { createStyles, makeStyles } from '@material-ui/core';

import { ICustomTheme } from 'admin/styles/theme';

export const styles = makeStyles((theme: ICustomTheme) =>
	createStyles({
		successSubmitWrapper: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '56px',
			borderRadius: '3px',
			color: '#fff',
			fontSize: '14px',
			margin: '20px 0 20px 0',
			padding: '24px 16px'
		},
		green: {
			backgroundColor: theme.colors.successGreen
		},
		red: {
			backgroundColor: theme.colors.dangerRed
		},
		generatingMessage: {
			backgroundColor: '#22a1da'
		}
	})
);
