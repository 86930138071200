import { createStyles, makeStyles } from '@material-ui/core';

export const styles = makeStyles(() =>
	createStyles({
		contentWrapper: {
			display: 'flex',
			height: '100%',
			backgroundColor: '#F8FAFC',

			'& *': {
				fontFamily: 'tt-commons-regular'
			}
		},

		appContent: {
			flex: 1,
			minHeight: '100%',
			overflow: 'auto'
		},

		suspenseWrapper: {
			flex: 1,
			overflow: 'auto',
			minHeight: 'calc(100% - 64px)',
			padding: '16px 30px'
		}
	})
);
