import { createStyles, makeStyles } from '@material-ui/core';

export const userPortalStyles = makeStyles(() =>
	createStyles({
		loadingAnimation: {
			position: 'absolute',
			zIndex: 9999,
			top: '70px',
			left: '50%',
			'& > div': {
				color: '#435E73'
			}
		}
	})
);
