import { createStyles, makeStyles } from '@material-ui/core';

export const adminPortalStyles = makeStyles(() =>
	createStyles({
		textFormField: {
			display: 'flex',
			alignItems: 'center',
			flex: 1
		},
		isVertical: {
			flexDirection: 'column',
			alignItems: 'unset',
			justifyContent: 'space-between'
		},
		textField: {
			'&.MuiFormControl-root': {
				width: '100%',
				'& .MuiInputBase-root': {
					'&:before': {
						borderBottom: ' 1px solid #ddd'
					},
					'&:after': {
						borderBottom: ' 2px solid #435e73'
					},
					'& input': {
						color: '#474747',
						textOverflow: 'ellipsis',
						fontSize: '13px',
						'&::placeholder': {
							fontSize: '13px'
						}
					},
					'&.Mui-error': {
						'&:after': {
							borderBottom: ' 2px solid #e51c23'
						}
					},
					'&.Mui-disabled': {
						opacity: '0.7',
						'& input': {
							cursor: 'not-allowed'
						}
					}
				}
			}
		}
	})
);
