import {
	AccountCircleRounded,
	Assignment,
	AttachMoney,
	CheckBox,
	Cloud,
	Comment,
	Computer,
	Dashboard,
	Description,
	DirectionsRun,
	FindInPage,
	HowToVote,
	ImportContacts,
	PeopleAlt,
	Policy,
	School,
	Warning
} from '@material-ui/icons';

import {
	CHECKLIST,
	DENTAL_TRAINING,
	DOCUMENT_AND_POLICIES,
	DOCUMENTS,
	EMERGENCY_PLANNING,
	HIPAA_RESOURCES,
	HIPAA_TRAINING,
	LOG_EXPOSURE,
	MY_ACCOUNT,
	OSHA_RESOURCES,
	OSHA_TRAINING,
	REFERRALS,
	REPORT_INCIDENT,
	SAFETY_DATA_SHEETS,
	SAVINGS_CALCULATOR,
	SELF_ASSESSMENT,
	SERVICES,
	USERS
} from 'user/routes/pathNames';
import { CourseCategory } from 'shared/models/Course';
import TrainingWarningIcon from 'user/features/officeSafe/trainingWarningIcon';

interface ILink {
	warningIcon?: JSX.Element;
	icon: JSX.Element;
	label: string;
	route: string;
	outer?: boolean;
}

export const hipaaLinks: ILink[] = [
	{
		label: 'Documents and Policies',
		icon: <Description />,
		route: DOCUMENT_AND_POLICIES
	},
	{
		label: 'Report an Incident',
		icon: <Comment />,
		route: REPORT_INCIDENT
	},
	{
		label: 'Emergency Planning',
		icon: <Warning />,
		route: EMERGENCY_PLANNING
	},
	{
		label: 'Resources',
		icon: <Dashboard />,
		route: HIPAA_RESOURCES
	},
	{
		label: 'HIPAA Checklist',
		icon: <CheckBox />,
		route: CHECKLIST
	}
];

export const oshaLinks: ILink[] = [
	{
		label: 'Documents',
		icon: <Assignment />,
		route: DOCUMENTS
	},
	{
		label: 'Safety Data Sheets',
		icon: <DirectionsRun />,
		route: SAFETY_DATA_SHEETS
	},
	{
		label: 'Self-Assessment',
		icon: <CheckBox />,
		route: SELF_ASSESSMENT
	},
	{
		label: 'Log a BBP Exposure Incident',
		icon: <FindInPage />,
		route: LOG_EXPOSURE
	},
	{
		label: 'Resources',
		icon: <Dashboard />,
		route: OSHA_RESOURCES
	}
];

export const pciLinks: ILink[] = [
	{
		label: 'PCI Compliance',
		icon: <Policy />,
		route: 'https://complywithpci.com/safemaker/login/portal',
		outer: true
	},
	{
		label: 'Merchant Savings Calculator',
		icon: <AttachMoney />,
		route: SAVINGS_CALCULATOR
	}
];

export const officeSafeLinks: ILink[] = [
	{
		label: 'HIPAA Training',
		icon: <School />,
		warningIcon: <TrainingWarningIcon category={CourseCategory.Hipaa} />,
		route: HIPAA_TRAINING
	},
	{
		label: 'OSHA Training',
		icon: <ImportContacts />,
		warningIcon: <TrainingWarningIcon category={CourseCategory.Osha} />,
		route: OSHA_TRAINING
	},
	{
		label: 'Dental CE Training',
		icon: <Computer />,
		route: DENTAL_TRAINING
	}
];

export const settingsLinks: ILink[] = [
	{
		label: 'My Account',
		icon: <AccountCircleRounded />,
		route: MY_ACCOUNT
	},
	{
		label: 'My Users',
		icon: <PeopleAlt />,
		route: USERS
	},
	{
		label: 'Services',
		icon: <Cloud />,
		route: SERVICES
	},
	{
		label: 'Refer a Practice',
		icon: <HowToVote />,
		route: REFERRALS
	}
];
