export const handleClasses = (
	portal: 'adminPortal' | 'userPortal' | 'enterprisePortal',
	adminStyles: any,
	userStyles: any,
	enterpriseStyles?: any
) => {
	if (portal === 'adminPortal') {
		return adminStyles;
	} else if (portal === 'userPortal') {
		return userStyles;
	}
	return enterpriseStyles;
};
