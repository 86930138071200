import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { adminSelector } from 'shared/store';
import { links } from './navLinks';
import SignOut from 'shared/components/signOut';
import { styles } from './styles';

const Sidebar = () => {
	const classes = styles();

	const admin = useSelector(adminSelector);

	const authorizedLinks = links.filter((link) => {
		if (!link.adminTypes) {
			return true;
		}
		return !!admin && link.adminTypes.includes(admin.type);
	});

	return (
		<aside className={classes.sidebarWrapper}>
			{authorizedLinks.map((item, index) => (
				<div key={index}>
					{item.isAccount && <h3 className={classes.settingsTitle}>SETTINGS</h3>}
					<NavLink className={classes.sidebarLink} to={item.route}>
						<span className={classes.sidebarIcon}>{item.icon}</span>
						<span className={classes.sidebarLabel}>{item.label}</span>
					</NavLink>
				</div>
			))}

			<SignOut portal="adminPortal" />
		</aside>
	);
};

export default Sidebar;
