import { lazy } from 'react';

// Admin
export const Admin = lazy(() => import('../features/admin'));
export const AdminList = lazy(() => import('../features/admin/list'));
export const AdminForm = lazy(() => import('../features/admin/form'));

// Certificate
export const Certificate = lazy(() => import('../features/certificate'));

// Client
export const Client = lazy(() => import('../features/client'));
export const ClientList = lazy(() => import('../features/client/list'));
export const ClientForm = lazy(() => import('../features/client/form'));
export const ClientView = lazy(() => import('../features/client/view'));

// Company
export const Company = lazy(() => import('../features/company'));
export const CompanyList = lazy(() => import('../features/company/list'));
export const CompanyForm = lazy(() => import('../features/company/form'));

// Dashboard
export const Dashboard = lazy(() => import('../features/dashboard'));

// Document
export const Document = lazy(() => import('../features/document'));

// Export
export const Export = lazy(() => import('../features/export'));

// Global Settings
export const GlobalSettings = lazy(() => import('../features/globalSettings'));

// Merchant Template
export const MerchantTemplate = lazy(() => import('../features/merchantTemplate'));
export const MerchantTemplateList = lazy(
	() => import('../features/merchantTemplate/list')
);
export const TransactionTemplateForm = lazy(
	() => import('../features/merchantTemplate/forms/transactionTemplate')
);
export const SaleTemplateForm = lazy(
	() => import('../features/merchantTemplate/forms/saleTemplate')
);

// Organization
export const Organization = lazy(() => import('../features/organization'));
export const OrganizationList = lazy(() => import('../features/organization/list'));
export const OrganizationForm = lazy(() => import('../features/organization/form'));
export const OrganizationView = lazy(() => import('../features/organization/view'));
export const ViewPayment = lazy(
	() => import('../features/organization/view/payments/createApplication/addPayment')
);
export const SalesSummaryForm = lazy(
	() =>
		import(
			'../features/organization/view/documents/hipaaSalesSummary/editSalesSummary'
		)
);
export const ViewSalesSummary = lazy(
	() =>
		import(
			'../features/organization/view/documents/hipaaSalesSummary/viewSalesSummary'
		)
);
export const UserForm = lazy(
	() => import('../features/organization/view/users/form')
);
export const LocationForm = lazy(
	() => import('../features/organization/view/payments/locationForm')
);

// Personal Account
export const PersonalAccount = lazy(() => import('../features/myAccount'));

// Referral
export const Referral = lazy(() => import('../features/referral'));
export const ReferralList = lazy(() => import('../features/referral/list'));

// Reseller
export const Reseller = lazy(() => import('../features/reseller'));
export const ResellerList = lazy(() => import('../features/reseller/list'));
export const ResellerForm = lazy(() => import('../features/reseller/form'));

// Search
export const Search = lazy(() => import('../features/search'));
