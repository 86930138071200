import { createAsyncThunk } from '@reduxjs/toolkit';

import AuthService, { ILoginResponse } from 'shared/services/AuthService';
import IUser from 'shared/models/User';
import UserService from 'shared/services/UserService';

interface ILoginInput {
	email: string;
	password: string;
}

interface IRejectValue {
	error: string;
}

export const login = createAsyncThunk<
	ILoginResponse,
	ILoginInput,
	{ rejectValue: IRejectValue }
>('auth/login', async ({ email, password }, thunkAPI) => {
	try {
		const { user, isAdmin } = await AuthService.login(email, password);
		return { user, isAdmin };
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response?.data);
	}
});

export const loginAsUser = createAsyncThunk<
	IUser,
	string,
	{ rejectValue: IRejectValue }
>('auth/loginAsUser', async (id, thunkAPI) => {
	try {
		const user = await AuthService.loginAsUser(id);
		return user;
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response?.data);
	}
});

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
	try {
		await AuthService.logout();
		return true;
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response?.data);
	}
});

export const getUserData = createAsyncThunk(
	'auth/getUserData',
	async (userId: string) => {
		const user = await UserService.findById(userId);
		return user;
	}
);

export const fetchCurrentUser = createAsyncThunk(
	'auth/current',
	async (_, thunkAPI) => {
		try {
			const { user, isAdmin } = await AuthService.fetchCurrentUser();
			return { user, isAdmin };
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response?.data);
		}
	}
);
