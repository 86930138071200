import { Navigate, RouteObject } from 'react-router-dom';

import * as LazyComponent from 'admin/utils/LazyComponents';
import * as PathNames from 'admin/routes/pathNames';
import { AdminType } from 'shared/models/Admin';

interface IRoute extends RouteObject {
	adminTypes?: AdminType[];
}

const routes: IRoute[] = [
	{
		path: PathNames.DASHBOARD,
		element: <LazyComponent.Dashboard />,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin]
	},
	{
		path: PathNames.SEARCH,
		element: <LazyComponent.Search />,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin]
	},
	{
		path: PathNames.ORGANIZATIONS,
		element: <LazyComponent.Organization />,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin],
		children: [
			{
				path: PathNames.ORGANIZATIONS,
				element: <LazyComponent.OrganizationList />
			},
			{
				path: PathNames.NEW_ORGANIZATION,
				element: <LazyComponent.OrganizationForm />
			},
			{
				path: PathNames.VIEW_ORGANIZATION,
				element: <LazyComponent.OrganizationView />
			},
			{
				path: PathNames.VIEW_ORGANIZATION_USER,
				element: <LazyComponent.UserForm />
			},
			{
				path: PathNames.VIEW_SALES_SUMMARY,
				element: <LazyComponent.ViewSalesSummary />
			},
			{
				path: PathNames.EDIT_SALES_SUMMARY,
				element: <LazyComponent.SalesSummaryForm />
			},
			{
				path: PathNames.NEW_SALES_SUMMARY,
				element: <LazyComponent.SalesSummaryForm />
			},
			{
				path: PathNames.ADD_LOCATION,
				element: <LazyComponent.LocationForm />
			},
			{
				path: PathNames.EDIT_LOCATION,
				element: <LazyComponent.LocationForm />
			},
			{
				path: PathNames.VIEW_PAYMENT,
				element: <LazyComponent.ViewPayment />
			}
		]
	},
	{
		path: PathNames.REFERRALS,
		element: <LazyComponent.Referral />,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin],
		children: [
			{
				path: PathNames.REFERRALS,
				element: <LazyComponent.ReferralList />
			}
		]
	},
	{
		path: PathNames.DOCUMENTS,
		element: <LazyComponent.Document />,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin]
	},
	{
		path: PathNames.RESELLERS,
		element: <LazyComponent.Reseller />,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin],
		children: [
			{
				path: PathNames.RESELLERS,
				element: <LazyComponent.ResellerList />
			},
			{
				path: PathNames.VIEW_RESELLER,
				element: <LazyComponent.ResellerForm />
			},
			{
				path: PathNames.NEW_RESELLER,
				element: <LazyComponent.ResellerForm />
			}
		]
	},
	{
		path: PathNames.COMPANIES,
		element: <LazyComponent.Company />,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin],
		children: [
			{
				path: PathNames.COMPANIES,
				element: <LazyComponent.CompanyList />
			},
			{
				path: PathNames.VIEW_COMPANY,
				element: <LazyComponent.CompanyForm />
			},
			{
				path: PathNames.NEW_COMPANY,
				element: <LazyComponent.CompanyForm />
			}
		]
	},
	{
		path: PathNames.GLOBAL_SETTINGS,
		element: <LazyComponent.GlobalSettings />,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin]
	},
	{
		path: PathNames.CERTIFICATES,
		element: <LazyComponent.Certificate />,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin]
	},
	{
		path: PathNames.ACCOUNT,
		element: <LazyComponent.PersonalAccount />
	},
	{
		path: PathNames.ADMINS,
		element: <LazyComponent.Admin />,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin, AdminType.PaymentAdmin],
		children: [
			{
				path: PathNames.ADMINS,
				element: <LazyComponent.AdminList />
			},
			{
				path: PathNames.VIEW_ADMIN,
				element: <LazyComponent.AdminForm />
			},
			{
				path: PathNames.NEW_ADMIN,
				element: <LazyComponent.AdminForm />
			}
		]
	},
	{
		path: PathNames.EXPORT,
		element: <LazyComponent.Export />,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin]
	},
	{
		path: PathNames.CLIENTS,
		element: <LazyComponent.Client />,
		adminTypes: [AdminType.PaymentAdmin, AdminType.PaymentMember],
		children: [
			{
				path: PathNames.CLIENTS,
				element: <LazyComponent.ClientList />
			},
			{
				path: PathNames.NEW_CLIENT,
				element: <LazyComponent.ClientForm />
			},
			{
				path: PathNames.VIEW_CLIENT,
				element: <LazyComponent.ClientView />
			}
		]
	},
	{
		path: PathNames.MERCHANT_TEMPLATES,
		element: <LazyComponent.MerchantTemplate />,
		children: [
			{
				path: PathNames.MERCHANT_TEMPLATES,
				element: <LazyComponent.MerchantTemplateList />
			},
			{
				path: PathNames.VIEW_SALES,
				element: <LazyComponent.SaleTemplateForm />
			},
			{
				path: PathNames.NEW_SALES,
				element: <LazyComponent.SaleTemplateForm />
			},
			{
				path: PathNames.VIEW_TRANSACTION,
				element: <LazyComponent.TransactionTemplateForm />
			},
			{
				path: PathNames.NEW_TRANSACTION,
				element: <LazyComponent.TransactionTemplateForm />
			}
		]
	},
	{
		path: '*',
		element: <Navigate replace to={PathNames.DASHBOARD} />,
		adminTypes: [AdminType.GlobalAdmin, AdminType.Admin]
	},
	{
		path: '*',
		element: <Navigate replace to={PathNames.CLIENTS} />,
		adminTypes: [AdminType.PaymentAdmin, AdminType.PaymentMember]
	}
];

export default routes;
