import { Navigate, RouteObject } from 'react-router-dom';

import * as LazyComponent from 'shared/utils/LazyComponents';
import config from 'shared/config';
import { Environment } from 'shared/store';

import * as PathNames from '../pathNames';

const publicRoutes: RouteObject[] = [
	{
		path: PathNames.LOGIN,
		element: <LazyComponent.Login />
	},
	{
		path: PathNames.FORGOT_PASSWORD,
		element: <LazyComponent.ForgotPassword />
	},
	{
		path: PathNames.NEW_PASSWORD,
		element: <LazyComponent.NewPassword />
	},
	{
		path: PathNames.RISK_ASSESSMENT,
		element: <LazyComponent.RiskAssessment />
	},
	{
		path: PathNames.RESELLER_RISK_ASSESSMENT,
		element: <LazyComponent.RiskAssessment />
	},
	{
		path: PathNames.COMPLETE_RISK_ASSESSMENT,
		element: <LazyComponent.RiskAssessment />
	},
	{
		path: PathNames.COMPLETE_RESELLER_RISK_ASSESSMENT,
		element: <LazyComponent.RiskAssessment />
	},
	{
		path: '*',
		element: <Navigate replace to={PathNames.LOGIN} />
	}
];

// TODO: return back the public routes once merging everything to the production environment
const productionPublicRoutes: RouteObject[] = [
	{
		path: PathNames.LOGIN,
		element: <LazyComponent.Login />
	},
	{
		path: PathNames.FORGOT_PASSWORD,
		element: <LazyComponent.ForgotPassword />
	},
	{
		path: PathNames.NEW_PASSWORD,
		element: <LazyComponent.NewPassword />
	},
	{
		path: '*',
		element: <Navigate replace to={PathNames.LOGIN} />
	}
];

let isRemoteServer =
	config.environment && Object.values(Environment).includes(config.environment);

const routes: RouteObject[] = isRemoteServer ? productionPublicRoutes : publicRoutes;

export default routes;
