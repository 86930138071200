import { AppState } from 'shared/store';

export const userAcknowledgmentsSelector = (state: AppState) =>
	state.userAccountInformation.userAcknowledgments;
export const userAcknowledgmentsLoadingSelector = (state: AppState) =>
	state.userAccountInformation.userAcknowledgmentsLoading;
export const userCertificatesSelector = (state: AppState) =>
	state.userAccountInformation.userCertificates;
export const userCertificatesLoadingSelector = (state: AppState) =>
	state.userAccountInformation.userCertificatesLoading;
