import axios, { AxiosRequestConfig } from 'axios';

import store, { logout } from 'shared/store';
import config from 'shared/config';

axios.defaults.baseURL = config.apiUrl;

const requestInterceptor = (config: AxiosRequestConfig) => {
	return { ...config, withCredentials: true };
};

axios.interceptors.request.use(requestInterceptor);

axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response?.status === 401) {
			store.dispatch(logout());
		} else if (error.response?.status === 404) {
			window.location.href = '/';
		}
		return Promise.reject(error);
	}
);

const uninterceptedAxiosInstance = axios.create();

uninterceptedAxiosInstance.interceptors.request.use(requestInterceptor);

export default uninterceptedAxiosInstance;
