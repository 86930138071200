import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';

import IUserQuiz, { QuizStatus } from 'shared/models/UserQuiz';
import config from 'shared/config';

export default class UserQuizService {
	public static async list(userId: string): Promise<IUserQuiz[]> {
		const response = await axios.get('/employee_quizzes');
		const quizzes: IUserQuiz[] = camelcaseKeys(response.data, { deep: true });
		return quizzes.map((quiz) => this.prepareQuiz(quiz, userId));
	}

	private static prepareQuiz(quiz: IUserQuiz, userId: string): IUserQuiz {
		return {
			...quiz,
			status: quiz.status || QuizStatus.Incomplete,
			imagePath:
				'https://hippa-training-courses-covers.s3.us-west-2.amazonaws.com/hipaa-quiz.png',
			certificateLink: this.getCertificateLink(quiz, userId)
		};
	}

	private static getCertificateLink(quiz: IUserQuiz, userId: string): string {
		const { userQuizId } = quiz;
		const quizUrl = `${config.pdfUrl}/${userId}/quiz_certificate`;
		return userQuizId ? `${quizUrl}/${userQuizId}` : '';
	}
}
