import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { listLongQuestions, listQuestionCategories } from './actions';
import IQuestionCategory from 'shared/models/QuestionCategory';
import ISection from 'shared/models/Section';

interface IState {
	isEditable: boolean;
	sections: ISection[];
	questionsLoading: boolean;
	questionCategories: IQuestionCategory[];
}

const initialState: IState = {
	isEditable: false,
	sections: [],
	questionsLoading: false,
	questionCategories: []
};

export const longRiskAssesssmentSlice = createSlice({
	name: 'longRiskAssesssment',
	initialState,
	reducers: {
		setLongRaEditable: (state, action: PayloadAction<boolean>) => {
			state.isEditable = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(listLongQuestions.pending, (state) => {
				state.questionsLoading = true;
			})
			.addCase(listLongQuestions.fulfilled, (state, action) => {
				state.sections = action.payload;
				state.questionsLoading = false;
				state.isEditable = false;
			})
			.addCase(listQuestionCategories.fulfilled, (state, action) => {
				state.questionCategories = action.payload;
			});
	}
});

export const { setLongRaEditable } = longRiskAssesssmentSlice.actions;

export const longRiskAssessmentReducer = longRiskAssesssmentSlice.reducer;
