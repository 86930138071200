import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';

import IAdmin, { AdminType } from 'shared/models/Admin';
import IUser, { UserType } from 'shared/models/User';
import uninterceptedAxiosInstance from 'shared/services/config';

export interface ILoginResponse {
	user: IAdmin | IUser;
	isAdmin: boolean;
}

export interface ILogoutResponse {}

export default class AuthService {
	public static async login(
		email: string,
		password: string
	): Promise<ILoginResponse> {
		const response = await uninterceptedAxiosInstance.post('/sessions/sign_in', {
			email,
			password
		});

		const isAdmin = (Object.values(AdminType) as string[]).includes(response.data);

		return {
			user: camelcaseKeys(response.data, {
				deep: true,
				stopPaths: ['personal_services', 'organization.personal_services']
			}),
			isAdmin
		};
	}

	public static async loginAsUser(id: string): Promise<IUser> {
		const response = await axios.post('/sessions/login_as_user', {
			user_id: id
		});

		return camelcaseKeys(response.data, {
			deep: true,
			stopPaths: ['personal_services', 'organization.personal_services']
		});
	}

	public static async logout(): Promise<ILogoutResponse> {
		const response = await uninterceptedAxiosInstance.post('/sessions/sign_out');

		return {
			stauts: response.data
		};
	}

	public static async fetchCurrentUser() {
		const response = await uninterceptedAxiosInstance.get('/sessions');

		return {
			user: camelcaseKeys(response.data, {
				deep: true,
				stopPaths: ['personal_services', 'organization.personal_services']
			}),
			isAdmin: false
		};
	}

	public static async validateEmail(
		email: string
	): Promise<(AdminType | UserType)[] | null> {
		return uninterceptedAxiosInstance
			.post('/users/validate_account', { email })
			.then((response) => {
				return response.data;
			})
			.catch(() => {
				return null;
			});
	}

	public static async resetPassword(
		email: string,
		type: AdminType | UserType
	): Promise<IAdmin | IUser> {
		const response = await axios.post('/users/reset', {
			email,
			type,
			send_new_link: true
		});

		return camelcaseKeys(response.data, {
			deep: true,
			stopPaths: ['personal_services', 'organization.personal_services']
		});
	}

	public static async validateToken(token: string): Promise<boolean> {
		return uninterceptedAxiosInstance
			.post('/users/validate_token', { token })
			.then((response) => {
				return response.data;
			})
			.catch(() => {
				return false;
			});
	}

	public static async updatePassword(id: string, password: string): Promise<void> {
		await axios.put(`/clients/reset/${id}`, { password });
	}
}
