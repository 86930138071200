import { createStyles, makeStyles } from '@material-ui/core';

export const styles = makeStyles(() =>
	createStyles({
		tooltip: {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			color: '#374151',
			fontSize: '16px',

			'&.bold': {
				fontWeight: 600
			},

			'&.textWrap': {
				whiteSpace: 'normal',
				display: '-webkit-box',
				WebkitLineClamp: 4,
				lineHeight: '16px !important',
				WebkitBoxOrient: 'vertical',
				wordBreak: 'break-word'
			},

			'&.location': {
				fontSize: '16px',
				textDecorationLine: 'underline',
				color: '#3B82F6',
				cursor: 'pointer'
			},

			'& .highlight': {
				color: '#4DA9E7'
			}
		}
	})
);
