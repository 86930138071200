import { createStyles, makeStyles } from '@material-ui/core';

export const userPortalStyles = makeStyles(() =>
	createStyles({
		pageSubtitle: {
			fontWeight: 400,
			color: '#444',
			fontSize: '20px',
			marginBottom: '12px',
			'&.leadTableHead': {
				color: '#00B346'
			},
			'&.exportTableHead': {
				color: '#FFB63E',
				marginTop: '24px'
			},
			'&.appointmentsTableHead': {
				color: '#444',
				marginTop: '24px'
			},
			'&.SalesTableHead': {
				marginTop: '24px'
			},
			'&.practiceInfoTitle': {
				textAlign: 'center',
				margin: '0 0 10px 0'
			}
		},
		pageSubtitleDot: {
			marginRight: '20px'
		}
	})
);
