import IEvaluation from './Evaluation';
import IUser from './User';

export enum OrganizationType {
	Practice = 'practice',
	FinancialInstitution = 'financial_institution',
	BusinessAssociate = 'business_associate'
}

export enum OrganizationStatus {
	Lead = 'lead',
	Client = 'client',
	Disabled = 'disabled'
}

export enum OrganizationPortal {
	New = 'new',
	Forbidden = 'forbidden',
	Granted = 'granted',
	TokenReset = 'token_reset'
}

export enum OrganizationRaStatus {
	NotStarted = 'Not Started',
	Incomplete = 'Incomplete',
	Complete = 'Complete'
}

export const readableOrganizationStatus = {
	[OrganizationStatus.Lead]: 'Lead',
	[OrganizationStatus.Client]: 'Client',
	[OrganizationStatus.Disabled]: 'Disabled Client'
};

export const readableOrganizationType = {
	[OrganizationType.Practice]: 'Practice',
	[OrganizationType.FinancialInstitution]: 'Financial Institution',
	[OrganizationType.BusinessAssociate]: 'Business Associate'
};

export interface IPersonalService {
	'Email Encryption'?: boolean;
	'Data Backup'?: boolean;
	'Guaranteed Expense Reimbursement Policy'?: boolean;
	'OfficeSafe Pay'?: boolean;
	'OfficeSafe ID'?: boolean;
}

export default interface IOrganization {
	id: string;
	name: string;
	createdAt: Date;
	doctorName: string;
	phoneNumber: string;
	type: OrganizationType;
	code: string;
	addressLine1: string | null;
	addressLine2: string | null;
	state: string | null;
	city: string | null;
	zipCode: string | null;
	source: string;
	portalStatus: OrganizationPortal | null;
	website: string | null;
	status: OrganizationStatus;
	exported: boolean;
	privacyOfficer: string | null;
	securityOfficer: string | null;
	lastEvaluation?: IEvaluation;
	lastRaStatus: OrganizationRaStatus;
	users?: IUser[];
	// HIPAA Access
	availablePortal: boolean;
	clientEffectiveDate: Date | null;
	// OSHA Access
	availableOshaPortal: boolean;
	oshaEffectiveDate: Date | null;
	// Personal Services
	personalServices: IPersonalService | null;
	// Enterprise
	enterpriseId?: string;
	// Training
	hipaaTraining?: boolean;
	oshaTraining?: boolean;
}
