import { createStyles, makeStyles } from '@material-ui/core';

export const styles = makeStyles(() =>
	createStyles({
		authWrapper: {
			display: 'flex',
			height: '100%'
		}
	})
);
