import { AppState } from 'shared/store';

export const longRaEditableSelector = (state: AppState) =>
	state.longRiskAssessment.isEditable;
export const sectionsSelector = (state: AppState) =>
	state.longRiskAssessment.sections;
export const longQuestionsLoadingSelector = (state: AppState) =>
	state.longRiskAssessment.questionsLoading;
export const questionCategoriesSelector = (state: AppState) =>
	state.longRiskAssessment.questionCategories;
