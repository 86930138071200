import { createStyles, makeStyles } from '@material-ui/core';

export const adminPortalStyles = makeStyles(() =>
	createStyles({
		formWrapper: {
			margin: '0 auto',
			minWidth: '300px',
			maxWidth: '700px',
			'& > div': {
				marginBottom: '12px',
				position: 'relative'
			},
			'&.transactionTemplateForm': {
				margin: '0',
				maxWidth: 'unset',
				marginTop: '35px',
				'& > div': {
					'&:last-of-type': {
						justifyContent: 'center'
					}
				}
			},
			'&.exportUsers': {
				margin: 0,
				maxWidth: 'unset'
			},
			'&.practiceDocumentsForm': {
				flex: 1,
				margin: 0,
				maxWidth: 'unset',
				'& > div': {
					'&:last-of-type': {
						justifyContent: 'center'
					}
				}
			}
		},
		description: {
			marginBottom: '16px'
		},
		formActions: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			marginTop: '30px'
		}
	})
);
