import { createStyles, makeStyles } from '@material-ui/core';

import { ICustomTheme } from 'admin/styles/theme';

export const styles = makeStyles((theme: ICustomTheme) =>
	createStyles({
		headerWrapper: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			height: '80px',
			backgroundColor: theme.colors.white,
			boxShadow: '0 1px 2px rgb(0 0 0 / 30%)',
			padding: '0 50px',
			position: 'sticky',
			inset: '0 0 0 0',
			zIndex: 10
		},
		headerRightSide: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			backgroundColor: theme.colors.white
		},
		pcihipaaLogo: {
			display: 'flex',
			alignItems: 'center',
			textDecoration: 'none',
			color: '#8E8C8E',
			fontSize: '24px',
			fontWeight: 'bold',
			'& > svg': {
				height: '50px',
				width: '60px'
			}
		},
		sideDrawer: {
			'& > div': {
				'&:nth-child(2)': {
					padding: '8px 20px 0px 20px',
					'& form': {
						margin: '0 !important',
						'& > div': {
							margin: '0',
							'& > p': {
								display: 'none'
							}
						}
					}
				}
			}
		},
		userInfoWrapper: {
			position: 'relative',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			gap: '20px',
			cursor: 'pointer'
		},
		userInfo: {
			display: 'flex',
			alignItems: 'center',
			gap: '16px',

			'& > div': {
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				gap: '4px',

				'& > p': {
					'&:first-of-type': {
						color: '#5B7C8F',
						fontWeight: 600,
						fontSize: '14px'
					},
					'&:last-of-type': {
						color: '#94A3B8',
						fontWeight: 400,
						fontSize: '10px'
					}
				}
			}
		},
		arrow: {
			transition: 'transform .2s ease-in-out',
			cursor: 'pointer'
		}
	})
);
