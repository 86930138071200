import { createAsyncThunk } from '@reduxjs/toolkit';

import UserService, { IUserCertificate } from 'user/services/UserService';
import IEmployeeAcknowledgment from 'shared/models/EmployeeAcknowledgment';

export const getUserAcknowledgments = createAsyncThunk<
	IEmployeeAcknowledgment[],
	string
>(
	'users/myAccount/acknowledgments',
	async (userId) => await UserService.findAcknowledgments(userId)
);

export const getUserCertificates = createAsyncThunk<IUserCertificate[], string>(
	'users/myAccount/certificates',
	async (userId) => await UserService.findCertificates(userId)
);
