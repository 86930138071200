import { createStyles, makeStyles } from '@material-ui/core';

export const styles = makeStyles(() =>
	createStyles({
		footerWrapper: {
			backgroundColor: '#fff',
			height: '60px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '0 10px',
			'& > p': {
				fontSize: '13px',
				color: '#474747',
				'& > a': {
					textDecoration: 'none',
					color: '#435E73'
				}
			}
		}
	})
);
