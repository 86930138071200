import { lazy } from 'react';

// Login
export const Login = lazy(() => import('../features/login'));

// Password
export const ForgotPassword = lazy(() => import('../features/forgotPassword'));
export const NewPassword = lazy(() => import('../features/newPassword'));

// Risk Assessment
export const RiskAssessment = lazy(() => import('../features/riskAssessment'));
