import { Environment } from 'shared/store';

const apiUrl = process.env.REACT_APP_API_URL;

const config = {
	apiUrl,
	pdfUrl: apiUrl?.replace('api', 'pdf'),
	vivaLearningCompanyToken: '4314EFA2-FDE5-4FA9-9257-053FD7B60D56',
	environment: process.env.REACT_APP_ENV as Environment | undefined
};

export default config;
