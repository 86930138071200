import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';

import IOrganization, {
	IPersonalService,
	OrganizationRaStatus,
	OrganizationStatus,
	OrganizationType
} from 'shared/models/Organization';
import IBusinessAssociateSale from 'shared/models/BusinessAssociateSale';
import IClientLocation from 'shared/models/ClientLocation';
import IEvaluation from 'shared/models/Evaluation';
import IFinancialInstitutionSale from 'shared/models/FinancialInstitutionSale';
import IMerchantApplication from 'shared/models/MerchantApplication';
import IPaginatedResponse from 'shared/services/PaginatedResponse';
import IReferral from 'shared/models/Referral';
import ISale from 'shared/models/Sale';
import IUser from 'shared/models/User';

export type SortField = 'name' | 'doctorName' | 'createdAt';
export type UsersSortField = 'firstName' | 'lastName';

interface ISearchProps {
	limit: number;
	offset: number;
	type: OrganizationType;
	status?: OrganizationStatus;
	raStatus?: OrganizationRaStatus;
	sort: SortField;
	order: 'asc' | 'desc';
}

interface IOrganizationUsersProps {
	sort: any;
	order?: 'asc' | 'desc';
}

interface IAccessProps {
	hasAccess: boolean;
	personalServices?: IPersonalService;
}

export interface IFindByIdResponse {
	organization: IOrganization;
	locations: IClientLocation[];
	financialInstitutionSales: IFinancialInstitutionSale[];
	businessAssociateSales: IBusinessAssociateSale[];
	merchantApplications: IMerchantApplication[];
	evaluations: IEvaluation[];
	referrals: IReferral[];
	sale: ISale;
}

interface IOrganizationProps {
	doctorName?: string;
	name: string;
	type: OrganizationType;
	addressLine1: string;
	addressLine2: string;
	state: string;
	city: string;
	zipCode: string;
	portalStatus?: string;
	oshaPortalStatus?: string;
	phoneNumber: string;
	code?: string;
	privacyOfficer?: string;
	securityOfficer?: string;
	source?: string;
	status: OrganizationStatus;
	personalServices?: IPersonalService;
	clientEffectiveDate?: Date;
	oshaEffectiveDate?: Date;
	website?: string;
}

export default class OrganizationService {
	public static async list(
		props: ISearchProps
	): Promise<IPaginatedResponse<IOrganization>> {
		const url = `/organizations/${this.mapUrl(props.type)}`;

		const config = {
			params: {
				limit: props.limit,
				offset: props.offset,
				paginated: true,
				status: props.status,
				sort: this.mapSortField(props.sort),
				order: props.order,
				last_ra_status: props.raStatus
			}
		};

		const response = await axios.get(url, config);
		return {
			total: response.data[this.mapTotalField(props.type)],
			data: camelcaseKeys(response.data[this.mapField(props.type)], {
				deep: true,
				stopPaths: ['personal_services']
			})
		};
	}

	public static async getLeadOrganizationsSources(): Promise<string[]> {
		const url = '/organizations/sources';
		const response = await axios.get(url);
		return response.data;
	}

	public static async findById(id: string): Promise<IFindByIdResponse> {
		const targetUrls = [
			'',
			'locations',
			'financial_institution_sales',
			'business_associate_sales',
			'merchant_applications',
			'evaluations',
			'referrals',
			'sale'
		];

		const promises = targetUrls.map((targetUrl) =>
			axios.get(`/organizations/${id}/${targetUrl}`)
		);

		const responses = await Promise.all(promises);

		const formattedResponses = camelcaseKeys(responses, {
			deep: true,
			stopPaths: ['data.personal_services']
		});

		return {
			organization: formattedResponses[0].data,
			locations: formattedResponses[1].data,
			financialInstitutionSales: formattedResponses[2].data,
			businessAssociateSales: formattedResponses[3].data,
			merchantApplications: formattedResponses[4].data,
			evaluations: formattedResponses[5].data,
			referrals: formattedResponses[6].data,
			sale: formattedResponses[7].data
		};
	}

	public static async get(id: string): Promise<IOrganization> {
		const response = await axios.get(`/organizations/${id}`);
		return camelcaseKeys(response.data, { deep: true });
	}

	public static async create(props: IOrganizationProps): Promise<IOrganization> {
		const response = await axios.post('/organizations', {
			...this.mapPropsToRequest(props)
		});

		return camelcaseKeys(response.data, {
			deep: true,
			stopPaths: ['personal_services']
		});
	}

	public static async update(
		id: string,
		props: IOrganizationProps
	): Promise<IOrganization> {
		const response = await axios.put(`/organizations/${id}`, {
			...this.mapPropsToRequest(props)
		});

		return camelcaseKeys(response.data, {
			deep: true,
			stopPaths: ['personal_services']
		});
	}

	public static async updateHipaaAccess(
		id: string,
		props: IAccessProps
	): Promise<IOrganization> {
		const response = await axios.put(`/organizations/${id}/portal`, {
			portal_access: props.hasAccess,
			personal_services: props?.personalServices
		});

		return camelcaseKeys(response.data, {
			deep: true,
			stopPaths: ['personal_services']
		});
	}

	public static async updateOshaAccess(
		id: string,
		props: IAccessProps
	): Promise<IOrganization> {
		const response = await axios.put(`/organizations/${id}/osha_portal`, {
			osha_portal_status: props.hasAccess
		});

		return camelcaseKeys(response.data, {
			deep: true,
			stopPaths: ['personal_services']
		});
	}

	public static async exportOne(
		id: string,
		exported: boolean
	): Promise<IOrganization> {
		const response = await axios.put(`/organizations/${id}/export`, {
			exported
		});

		return camelcaseKeys(response.data, {
			deep: true,
			stopPaths: ['personal_services']
		});
	}

	public static async exportMany(ids: string[]): Promise<void> {
		await axios.put('/organizations/export_batch', {
			organizations_ids: ids
		});
	}

	public static async delete(id: string): Promise<boolean> {
		const response = await axios
			.delete(`/organizations/${id}`)
			.then(() => {
				return true;
			})
			.catch(() => {
				return false;
			});

		return response;
	}

	public static async getUsers(
		id: string | undefined,
		props: IOrganizationUsersProps
	): Promise<IUser[]> {
		const url = `/organizations/${id}/users`;

		const config = {
			params: {
				sort: this.mapUsersSortField(props.sort),
				order: props.order
			}
		};

		const response = await axios.get(url, config);
		return camelcaseKeys(response.data, { deep: true });
	}

	private static mapUrl(type: OrganizationType): string {
		const urls = {
			[OrganizationType.Practice]: 'practices',
			[OrganizationType.FinancialInstitution]: 'financial-institutions',
			[OrganizationType.BusinessAssociate]: 'business-associate'
		};

		return urls[type];
	}

	private static mapSortField(sort: SortField): string {
		const sortFields = {
			name: 'name',
			doctorName: 'doctor_name',
			createdAt: 'created_at'
		};

		return sortFields[sort];
	}

	private static mapUsersSortField(sort: UsersSortField): string {
		const sortFields = {
			firstName: 'first_name',
			lastName: 'last_name'
		};

		return sortFields[sort];
	}

	private static mapTotalField(type: OrganizationType): string {
		const totalFields = {
			[OrganizationType.Practice]: 'total_practices',
			[OrganizationType.FinancialInstitution]: 'total_financial_institutions',
			[OrganizationType.BusinessAssociate]: 'total_business_associate'
		};

		return totalFields[type];
	}

	private static mapField(type: OrganizationType): string {
		const fields = {
			[OrganizationType.Practice]: 'practices',
			[OrganizationType.FinancialInstitution]: 'financial_institutions',
			[OrganizationType.BusinessAssociate]: 'business_associate'
		};

		return fields[type];
	}

	private static mapPropsToRequest(props: IOrganizationProps): object {
		return {
			doctor_name: props.doctorName,
			name: props.name,
			type: props.type,
			address_line_1: props.addressLine1,
			address_line_2: props.addressLine2,
			state: props.state,
			city: props.city,
			zip_code: props.zipCode,
			portal_status: props.portalStatus,
			osha_portal_status: props.oshaPortalStatus,
			phone_number: props.phoneNumber,
			code: props.code,
			privacy_officer: props.privacyOfficer,
			security_officer: props.securityOfficer,
			source: props.source,
			status: props.status,
			personal_services: props.personalServices,
			client_effective_date: props.clientEffectiveDate,
			osha_effective_date: props.oshaEffectiveDate,
			website: props.website
		};
	}
}
