import { lazy } from 'react';

// Documents And Policies
export const DocumentsAndPolicies = lazy(
	() => import('../features/hipaa/documentsAndPolicies')
);
export const DocumentsAndPoliciesView = lazy(
	() => import('../features/hipaa/documentsAndPolicies/view')
);
export const HipaaRiskAssessment = lazy(
	() => import('../features/hipaa/documentsAndPolicies/riskAssessment/questions')
);

// Report Incident
export const ReportIncident = lazy(() => import('../features/hipaa/reportIncident'));

// Emergency Planning
export const EmergencyPlanning = lazy(
	() => import('../features/hipaa/emergencyPlanning')
);

// HIPAA Resources
export const HipaaResources = lazy(() => import('../features/hipaa/resources'));

// HIPAA Checklist
export const HipaaChecklist = lazy(() => import('../features/hipaa/checklist'));

// Documents
export const Documents = lazy(() => import('../features/osha/documents'));

// Safety Data Sheets
export const SafetyDataSheets = lazy(
	() => import('../features/osha/safetyDataSheets')
);

// Self Assessment
export const SelfAssessment = lazy(() => import('../features/osha/selfAssessment'));

// Log Exposure
export const LogExposure = lazy(() => import('../features/osha/logExposure'));

// OSHA Resources
export const OshaResources = lazy(() => import('../features/osha/resources'));

// Savings Calculator
export const SavingsCalculator = lazy(
	() => import('../features/pci/savingsCalculator')
);

// HIPAA Training
export const HipaaTraining = lazy(
	() => import('../features/officeSafe/hipaaTraining')
);
export const HipaaTrainingList = lazy(
	() => import('../features/officeSafe/hipaaTraining/list')
);
export const HipaaTrainingView = lazy(
	() => import('../features/officeSafe/hipaaTraining/view')
);
export const HipaaTrainingSuccess = lazy(
	() => import('../features/officeSafe/hipaaTraining/view/success')
);

// OSHA Training
export const OshaTraining = lazy(
	() => import('../features/officeSafe/oshaTraining')
);
export const OshaTrainingList = lazy(
	() => import('../features/officeSafe/oshaTraining/list')
);
export const OshaTrainingView = lazy(
	() => import('../features/officeSafe/oshaTraining/view')
);
export const OshaTrainingSuccess = lazy(
	() => import('../features/officeSafe/oshaTraining/view/success')
);

// Dental Training
export const DentalTraining = lazy(
	() => import('../features/officeSafe/dentalTraining')
);

// My Account
export const MyAccount = lazy(() => import('../features/settings/myAccount'));

// Users
export const Users = lazy(() => import('../features/settings/users'));

// Services
export const Services = lazy(() => import('../features/settings/services'));

// Referrals
export const Referrals = lazy(() => import('../features/settings/referrals'));
