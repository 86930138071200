export interface ICustomTheme {
	colors: {
		antiFlashWhite: string;
		lightSilver: string;
		darkSilver: string;
		lightGray: string;
		lightBlue: string;
		mainBlue: string;
		darkGray: string;
		white: string;
		text: string;
		gray: string;
		silver: string;
		successGreen: string;
		dangerRed: string;
		blue: string;
	};
	organizationStatusColors: {
		lead: string;
		client: string;
		disabled: string;
	};
	adminTypeColors: {
		globalAdmin: string;
		admin: string;
		paymentAdmin: string;
		paymentMember: string;
	};
}

const customTheme: ICustomTheme = {
	colors: {
		antiFlashWhite: 'f0f0f0',
		lightSilver: '#cfcfcf',
		darkSilver: '#c0c0c0',
		lightBlue: '#f5f5f5',
		mainBlue: '#3080DE',
		lightGray: '#929daf',
		darkGray: '#474747',
		silver: '#cfcfcf',
		text: '#435E73',
		white: '#fff',
		gray: '#eee',
		successGreen: '#16b452',
		dangerRed: '#cb171e',
		blue: '#4C7CD0'
	},
	organizationStatusColors: {
		lead: '#00B346',
		client: '#22A1DA',
		disabled: '#5F5F5F'
	},
	adminTypeColors: {
		globalAdmin: '#435E73',
		admin: '#22A1DA',
		paymentAdmin: '#FFB63E',
		paymentMember: '#BBB'
	}
};

export default customTheme;
