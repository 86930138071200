import { ChangeEvent } from 'react';

import { TextField } from '@material-ui/core';

import { adminPortalStyles } from './adminPortalStyles';
import { enterprisePortalStyles } from './enterprisePortalStyles';
import { handleClasses } from 'shared/utils/handleClasses';
import Image from 'shared/components/image';
import Label from 'shared/components/label';
import { userPortalStyles } from './userPortalStyles';

interface ITextFormFieldProps {
	portal: 'adminPortal' | 'userPortal' | 'enterprisePortal';
	onChange?: (event: ChangeEvent<{ value: any }>) => void;
	onKeyUp?: (event: ChangeEvent<{ value: any }>) => void;
	previousLabel?: string | JSX.Element;
	uncontrolledLabelWidth?: boolean;
	customClassName?: string;
	isNumberType?: boolean;
	noWrapLabel?: boolean;
	placeholder?: string;
	isDisabled?: boolean;
	isVertical?: boolean;
	afterLabel?: string;
	autoFocus?: boolean;
	icon?: JSX.Element;
	startIcon?: string;
	isBold?: boolean;
	label?: string;
	field?: any;
	error?: any;
	value?: any;
}

const TextFormField = ({
	uncontrolledLabelWidth = false,
	noWrapLabel = true,
	isDisabled = false,
	isVertical = false,
	customClassName,
	previousLabel,
	isNumberType,
	placeholder,
	afterLabel,
	autoFocus,
	startIcon,
	onChange,
	onKeyUp,
	portal,
	isBold,
	field,
	error,
	value,
	label,
	icon
}: ITextFormFieldProps) => {
	const classes = handleClasses(
		portal,
		adminPortalStyles(),
		userPortalStyles(),
		enterprisePortalStyles()
	);

	const handleTextFieldVariant = () => {
		switch (portal) {
			case 'adminPortal':
				return 'standard';
			case 'userPortal':
			case 'enterprisePortal':
				return 'outlined';
			default:
				return;
		}
	};

	const startIconComponent = (
		<Image className={classes.startIcon} src={startIcon || ''} />
	);

	return (
		<div
			className={`
				${customClassName ? customClassName : ''}
				${isVertical && classes.isVertical}
				${classes.textFormField}
			`}
		>
			{label && (
				<Label
					uncontrollableWidth={uncontrolledLabelWidth}
					noWrapLabel={noWrapLabel}
					isDisabled={isDisabled}
					isBold={isBold}
					label={label}
				/>
			)}

			{icon}

			{previousLabel && <Label isPreviousLabel label={previousLabel} />}

			<TextField
				InputProps={{ startAdornment: startIcon && startIconComponent }}
				variant={handleTextFieldVariant()}
				type={isNumberType && 'number'}
				className={classes.textField}
				placeholder={placeholder}
				autoFocus={autoFocus}
				disabled={isDisabled}
				onChange={onChange}
				onKeyUp={onKeyUp}
				value={value}
				error={error}
				{...field}
			/>

			{afterLabel && <Label isAfterLabel label={afterLabel} />}
		</div>
	);
};

export default TextFormField;
