import { createStyles, makeStyles } from '@material-ui/core';

export const styles = makeStyles(() =>
	createStyles({
		headerWrapper: {
			borderBottom: '1px solid #E2E8F0',
			height: '64px',
			padding: '0 24px',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			backgroundColor: '#fff'
		},
		organizationName: {
			maxWidth: '50%',
			color: '#374151',
			fontWeight: 500,
			fontSize: '24px',
			textTransform: 'capitalize',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis'
		},
		loader: {
			position: 'unset'
		},
		userInfoWrapper: {
			position: 'relative',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			gap: '20px',
			cursor: 'pointer'
		},
		userInfo: {
			display: 'flex',
			alignItems: 'center',
			gap: '16px',

			'& > div': {
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				gap: '4px',

				'& > p': {
					'&:first-of-type': {
						color: '#5B7C8F',
						textTransform: 'capitalize',
						fontWeight: 600,
						fontSize: '14px'
					},
					'&:last-of-type': {
						color: '#94A3B8',
						fontWeight: 400,
						fontSize: '12px',
						maxWidth: '200px',
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					}
				}
			}
		},
		arrow: {
			transition: 'transform .2s ease-in-out',
			cursor: 'pointer'
		}
	})
);
