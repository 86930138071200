import { useState } from 'react';

import { ClickAwayListener } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';

import AdminSidebar from 'admin/components/sidebar';
import ArrowDown from 'shared/assets/icons/arrow-down.svg';
import Avatar from 'shared/assets/icons/avatar.svg';
import EnterpriseNavigation from 'enterprise/components/enterpriseNavigation';
import GlobalSearch from '../globalSearch';
import HipaaLogo from 'shared/assets/images/logo-hipaa.png';
import Image from 'shared/components/image';
import OfficeSafeLogo from '../officeSafeLogo';
import SideDrawer from '../sideDrawer';
import { styles } from './styles';
import UserSidebar from 'user/components/sidebar';

interface IHeaderProps {
	portal: 'adminPortal' | 'userPortal';
}

const Header = ({ portal }: IHeaderProps) => {
	const matchesMediaQuery = useMediaQuery('(max-width:770px)');
	const classes = styles();

	const [open, setOpen] = useState(false);

	const handleClick = () => setOpen((prev) => !prev);

	const handleClickAway = () => setOpen(false);

	return (
		<header className={classes.headerWrapper}>
			<OfficeSafeLogo portal={portal} />
			{matchesMediaQuery ? (
				<>
					{portal === 'adminPortal' && (
						<SideDrawer
							notCloseAfterClickContent={<GlobalSearch />}
							closeAfterClickContent={<AdminSidebar />}
							customClass={classes.sideDrawer}
							portal="adminPortal"
						/>
					)}
					{portal === 'userPortal' && (
						<SideDrawer
							closeAfterClickContent={<UserSidebar />}
							portal="userPortal"
						/>
					)}
				</>
			) : (
				<div className={classes.headerRightSide}>
					{portal === 'adminPortal' && (
						<>
							<GlobalSearch />
							<img
								className={classes.pcihipaaLogo}
								alt="pcihipaa_Logo"
								src={HipaaLogo}
							/>
						</>
					)}
					{portal === 'userPortal' && (
						<ClickAwayListener onClickAway={handleClickAway}>
							<div className={classes.userInfoWrapper} onClick={handleClick}>
								<div className={classes.userInfo}>
									<Image src={Avatar} />
									<div>
										<p>Tom Cooper</p>
										<p>tom.cooper@enterprise.com</p>
									</div>
								</div>
								<Image
									style={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
									src={ArrowDown}
									className={classes.arrow}
								/>
								<div>{open && <EnterpriseNavigation />}</div>
							</div>
						</ClickAwayListener>
					)}
				</div>
			)}
		</header>
	);
};

export default Header;
