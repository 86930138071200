import { FormEvent, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { Search } from '@material-ui/icons';

import { DASHBOARD, SEARCH } from 'admin/routes/pathNames';
import Form from '../form';
import { styles } from './styles';
import TextFormField from '../formComponents/textField';

const GlobalSearch = () => {
	const [searchText, setSearchText] = useState<string>('');
	const navigate = useNavigate();
	const location = useLocation();
	const classes = styles();

	useEffect(() => {
		const queryParams = queryString.parse(location.search) as any;
		if (location.pathname === `${SEARCH}` && queryParams.q) {
			setSearchText(queryParams.q);
		}
	}, []);

	const search = () => {
		if (searchText.length > 0) {
			navigate({
				pathname: SEARCH,
				search:
					'?' +
					new URLSearchParams({
						q: searchText,
						organizationSort: 'createdAt',
						organizationOrder: 'desc',
						clientSort: 'firstName',
						clientOrder: 'asc'
					}).toString()
			});
		} else {
			navigate(DASHBOARD);
		}
	};

	const onSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		search();
	};

	return (
		<Form
			customClass={classes.globalSearchForm}
			onSubmit={(event) => onSubmit(event)}
			hasFormActions={false}
			portal="adminPortal"
		>
			<TextFormField
				onChange={(event) => setSearchText(event.target.value)}
				placeholder="Search"
				portal="adminPortal"
				value={searchText}
				previousLabel={
					<button
						className={classes.searchButton}
						disabled={!searchText}
						type="submit"
					>
						<Search />
					</button>
				}
			/>
		</Form>
	);
};

export default GlobalSearch;
