import * as pathNames from 'enterprise/routes/pathNames';
import {
	locationsActiveIcon,
	locationsIcon,
	usersActiveIcon,
	usersIcon
} from 'shared/assets/icons';

interface ILink {
	activeIcon: string;
	label: string;
	route: string;
	icon: string;
}

export const links: ILink[] = [
	{
		label: 'Locations',
		route: pathNames.LOCATIONS,
		icon: locationsIcon,
		activeIcon: locationsActiveIcon
	},
	{
		label: 'Users',
		route: pathNames.USERS,
		icon: usersIcon,
		activeIcon: usersActiveIcon
	}
];
