import IEmployeeAcknowledgment from './EmployeeAcknowledgment';
import IEmployeeQuizEvaluation from './EmployeeQuizEvaluation';
import IEnterprise from './Enterprise';
import IOrganization from './Organization';

export enum UserType {
	PracticeAdmin = 'practice_admin',
	PracticeMember = 'practice_member',
	FinancialInstitutionAdmin = 'financial_institution_admin',
	BusinessAssociateAdmin = 'business_associate_admin',
	BusinessAssociateMember = 'business_associate_member'
}

export enum ComplianceStatus {
	Incomplete = 'Incomplete',
	ExpiresSoon = 'Expires soon',
	Complete = 'Complete'
}

export const readablePracticeType = {
	[UserType.PracticeAdmin]: 'Practice Admin',
	[UserType.PracticeMember]: 'Practice Member'
};

export const readableFinancialType = {
	[UserType.FinancialInstitutionAdmin]: 'Financial Institution Admin'
};

export const readableBusinessType = {
	[UserType.BusinessAssociateAdmin]: 'Business Associate Admin',
	[UserType.BusinessAssociateMember]: 'Business Associate Member'
};

export const readableUserType = {
	...readablePracticeType,
	...readableFinancialType,
	...readableBusinessType
};

export enum UserStatus {
	Lead = 'lead',
	Client = 'client',
	Disabled = 'disabled'
}

export const readableUserStatus = {
	[UserStatus.Lead]: 'Lead',
	[UserStatus.Client]: 'Client',
	[UserStatus.Disabled]: 'Disabled Client'
};

export enum UserActivity {
	Active = 'active',
	Inactive = 'inactive'
}

export const readableUserActivity = {
	[UserActivity.Active]: 'Active',
	[UserActivity.Inactive]: 'Inactive'
};

export default interface IUser {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	type: UserType;
	exported: boolean;
	timeZone: string | null;
	activityStatus: UserActivity;
	scheduleTime: string | null;
	scheduleDate: Date | null;
	scheduleTimeFull: string;
	createdAt?: Date;
	organization?: IOrganization;
	employeeAcknowledgments?: IEmployeeAcknowledgment[];
	employeeQuizEvaluations?: IEmployeeQuizEvaluation[];
	hipaaCompliance: ComplianceStatus;
	oshaCompliance: ComplianceStatus;
	enterprise: IEnterprise;
}
