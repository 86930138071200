import { Navigate, RouteObject } from 'react-router-dom';

import * as LazyComponent from 'enterprise/utils/LazyComponents';
import * as PathNames from 'enterprise/routes/pathNames';

const routes: RouteObject[] = [
	{
		path: PathNames.LOCATIONS,
		element: <LazyComponent.LOCATIONS />
	},
	{
		path: PathNames.USERS,
		element: <LazyComponent.USERS />
	},
	{
		path: '*',
		element: <Navigate replace to={PathNames.LOCATIONS} />
	}
];

export default routes;
