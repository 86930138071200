import { configureStore } from '@reduxjs/toolkit';

import {
	coursesReducer,
	quizReducer,
	userAccountInformationReducer
} from 'user/store';
import {
	exportedLeadsReducer,
	longRiskAssessmentReducer,
	organizationReducer,
	shortRiskAssessmentReducer
} from 'admin/store';
import authReducer from './ducks/auth/reducer';

const store = configureStore({
	reducer: {
		// Auth
		auth: authReducer,
		// Admin Portal
		organization: organizationReducer,
		shortRiskAssessment: shortRiskAssessmentReducer,
		longRiskAssessment: longRiskAssessmentReducer,
		exportedLeads: exportedLeadsReducer,
		// User Portal
		courses: coursesReducer,
		quiz: quizReducer,
		userAccountInformation: userAccountInformationReducer
	}
});

export type AppState = ReturnType<typeof store.getState>;

export * from './ducks/auth';

export default store;
