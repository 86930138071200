import {
	Environment,
	fetchCurrentUser,
	fetchingCurrentUserSelector,
	subdomains
} from 'shared/store';
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import config from 'shared/config';
import Cookies from 'js-cookie';
import Loader from 'shared/components/loader';
import Routes from 'shared/routes';

const App = () => {
	const dispatch = useDispatch();
	const fetchingCurrentUser = useSelector(fetchingCurrentUserSelector);

	const env = config.environment;
	let subdomain = env ? subdomains[env as Environment] : '';
	let cookieOptions = env ? { path: '/', domain: '.officesafe.com' } : {};
	Cookies.remove('ent_unauthenticate', cookieOptions);
	Cookies.remove(`${subdomain}_unauthenticate`, cookieOptions);
	Cookies.remove(`${subdomain}_enterprise_admin`, cookieOptions);

	localStorage.clear();

	useEffect(() => {
		dispatch(fetchCurrentUser());
	}, []);

	return (
		<Fragment>
			{fetchingCurrentUser && <Loader />}
			{!fetchingCurrentUser && <Routes />}
		</Fragment>
	);
};

export default App;
