import ICustomerClient from './CustomerClient';
import { readableUserType } from './User';

export enum AdminType {
	GlobalAdmin = 'global_admin',
	Admin = 'admin',
	PaymentAdmin = 'customer_admin',
	PaymentMember = 'customer_partner'
}

export const readableAdminType = {
	[AdminType.GlobalAdmin]: 'Global Admin',
	[AdminType.Admin]: 'Admin',
	[AdminType.PaymentAdmin]: 'Payment Admin',
	[AdminType.PaymentMember]: 'Payment Member'
};

export const readableAdminOrUserType = {
	...readableAdminType,
	...readableUserType
};

export default interface IAdmin {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
	type: AdminType;
	forgetPasswordToken: string;
	forgetPasswordTokenTimestamp: string;
	companyId: string | null;
	companyName: string | null;
	otherCompany: string | null;
	currentCustomerId: string | null;
	customerClients: ICustomerClient[];
}
