import { createStyles, makeStyles } from '@material-ui/core';

export const userPortalStyles = makeStyles(() =>
	createStyles({
		sidebarLink: {
			display: 'flex',
			alignItems: 'center',
			color: '#fff',
			fontSize: '12px',
			textDecoration: 'none',
			cursor: 'pointer',
			padding: '9px 16px',
			'&:hover': {
				backgroundColor: '#3B79BF'
			}
		},
		sidebarIcon: {
			width: '17px',
			height: '17px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			'& svg': {
				width: '100%',
				height: '100%'
			}
		},
		sidebarLabel: {
			marginLeft: '8px'
		}
	})
);
