import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	fetchCurrentUser,
	getUserData,
	login,
	loginAsUser,
	logout
} from './actions';
import IAdmin from 'shared/models/Admin';
import IUser from 'shared/models/User';

interface IState {
	admin: IAdmin | null;
	user: IUser | null;
	loginLoading: boolean;
	errorMessage: string | null;
	isEditable: boolean;
	userLoading: boolean;
	fetchingCurrentUser: boolean;
}
// Develop and staging will be only for testing the dev and staging backend on
// ent.officesafe.com, that will be by change the env variable on amplify
export enum Environment {
	Develop = 'production_develop',
	Staging = 'production_staging',
	Production = 'production'
}

export const subdomains = {
	[Environment.Develop]: 'dev_',
	[Environment.Staging]: 'staging_',
	[Environment.Production]: 'ra_'
};

const initialState: IState = {
	admin: null,
	user: null,
	loginLoading: false,
	errorMessage: null,
	isEditable: false,
	userLoading: false,
	fetchingCurrentUser: true
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAdmin: (state, action: PayloadAction<IAdmin>) => {
			state.admin = action.payload;
		},
		setUser: (state, action: PayloadAction<IUser>) => {
			state.user = action.payload;
		},
		setAuthEditable: (state, action: PayloadAction<boolean>) => {
			state.isEditable = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(login.pending, (state) => {
				state.loginLoading = true;
			})
			.addCase(login.fulfilled, (state, action) => {
				const { user, isAdmin } = action.payload;
				if (isAdmin) {
					state.admin = user as IAdmin;
				} else {
					state.user = user as IUser;
				}
				state.loginLoading = false;
				state.errorMessage = null;
			})
			.addCase(login.rejected, (state, action) => {
				state.loginLoading = false;
				state.errorMessage = action.payload?.error || null;
			})
			.addCase(loginAsUser.pending, (state) => {
				state.loginLoading = true;
			})
			.addCase(loginAsUser.fulfilled, (state, action) => {
				state.user = action.payload;
				state.loginLoading = false;
				state.errorMessage = null;
			})
			.addCase(logout.fulfilled, (state) => {
				state.admin = null;
				state.user = null;
			})
			.addCase(fetchCurrentUser.pending, (state) => {
				state.fetchingCurrentUser = true;
			})
			.addCase(fetchCurrentUser.fulfilled, (state, action) => {
				const { user, isAdmin } = action.payload;
				if (isAdmin) {
					state.admin = user as IAdmin;
				} else {
					state.user = user as IUser;
				}
				state.fetchingCurrentUser = false;
				state.errorMessage = null;
			})
			.addCase(fetchCurrentUser.rejected, (state, action) => {
				state.fetchingCurrentUser = false;
				state.errorMessage = null;
			})
			.addCase(getUserData.pending, (state) => {
				state.userLoading = true;
			})
			.addCase(getUserData.fulfilled, (state, action) => {
				state.user = action.payload;
				state.userLoading = false;
			});
	}
});

export const { setAdmin, setUser, setAuthEditable } = authSlice.actions;

export default authSlice.reducer;
