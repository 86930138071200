import { Suspense } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { adminSelector, userSelector } from 'shared/store';
import AdminRoutes from 'admin/routes/privateRoutes';
import config from 'shared/config';
import EnterpriseRoutes from 'enterprise/routes/privateRoutes';
import EnterpriseRoutesNames from 'enterprise/routes/privateRoutes/routes';
import { Environment, fetchingCurrentUserSelector } from 'shared/store';
import Loader from 'shared/components/loader';
import PublicRoutes from 'shared/routes/publicRoutes';
import UserRoutes from 'user/routes/privateRoutes';

const Routes = () => {
	const admin = useSelector(adminSelector);
	const user = useSelector(userSelector);
	const fetchingCurrentUser = useSelector(fetchingCurrentUserSelector);


	let filteredEnterpriseRoutes = EnterpriseRoutesNames.filter((route) => {
		return route.path == location.pathname;
	});

	const getRoutes = () => {
		if (
			config.environment &&
			Object.values(Environment).includes(config.environment)
		) {
			if (user && user.enterprise) {
				return <EnterpriseRoutes />;
			} else {
				if (fetchingCurrentUser) {
					return
				}
				
				let url = config.apiUrl?.replace('api', '#');
				if (url) {
					window.location.href = url;
				} else {
					return (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								flexDirection: 'column',
								gap: '24px',
								justifyContent: 'center',
								height: '100%'
							}}
						>
							<h3>
								Please use this link to redirect you to the login page if it didn't
								redirect automaticaly
							</h3>
							<a href="https://ra.officesafe.com" target="_blank" rel="noreferrer">
								Login
							</a>
						</div>
					);
				}
			}
		} else {
			if (user) {
				if (filteredEnterpriseRoutes.length > 0 && user?.enterprise) {
					return <EnterpriseRoutes />;
				}
				return <UserRoutes user={user} />;
			}
			if (admin) {
				return <AdminRoutes admin={admin} />;
			}
			return <PublicRoutes />;
		}
	};

	return (
		<BrowserRouter>
			{ !fetchingCurrentUser && <Suspense fallback={<Loader />}>{getRoutes()}</Suspense>}
		</BrowserRouter>
	);
};

export default Routes;
