import { createStyles, makeStyles } from '@material-ui/core';

export const styles = makeStyles(() =>
	createStyles({
		formAction: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '36px',
			minWidth: '83px',
			padding: '0 10px',
			textTransform: 'uppercase',
			outline: 'none',
			boxShadow: '1px 1px 2px rgb(0 0 0 / 30%)',
			transition: 'all .2s',
			cursor: 'pointer',
			marginRight: '8px',
			borderRadius: '3px',
			backgroundRepeat: 'no-repeat',
			backgroundSize: '200%',
			backgroundPosition: '50%',
			fontSize: '13px',
			'&:last-child': {
				marginRight: '0'
			},
			'&:hover': {
				backgroundSize: '100%'
			}
		},
		cancelButton: {
			backgroundImage: 'radial-gradient(circle,#fff 80%,#f0f0f0 81%)',
			borderWidth: '1px 0 0 1px',
			borderColor: '#eee',
			color: '#474747'
		},
		submitButton: {
			backgroundImage: 'radial-gradient(circle,#435E73 80%,#384e60 81%)',
			color: '#fff',
			border: 'none',
			'&:disabled': {
				cursor: 'not-allowed',
				opacity: 0.6,
				'&:hover': {
					backgroundSize: '200%'
				}
			}
		},
		resetButton: {
			backgroundImage: 'radial-gradient(circle,#22a1da 80%,#1e8dc0 81%)',
			border: 'none',
			color: '#fff'
		}
	})
);
