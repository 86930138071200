export const LOGIN = '/';

// Password
export const FORGOT_PASSWORD = '/forgotPassword';
export const NEW_PASSWORD = '/resetPassword';

// Risk Assessment
export const RISK_ASSESSMENT = '/123';
export const RESELLER_RISK_ASSESSMENT = `${RISK_ASSESSMENT}/:resellerUrl`;
export const COMPLETE_RISK_ASSESSMENT = `${RISK_ASSESSMENT}/complete/:evaluationId`;
export const COMPLETE_RESELLER_RISK_ASSESSMENT = `${RESELLER_RISK_ASSESSMENT}/complete/:evaluationId`;
