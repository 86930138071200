import { adminPortalStyles } from './adminPortalStyles';
import { enterprisePortalStyles } from './enterprisePortalStyles';
import { handleClasses } from 'shared/utils/handleClasses';
import { userPortalStyles } from './userPortalStyles';

interface IPageSubtitleProps {
	portal: 'adminPortal' | 'userPortal' | 'enterprisePortal';
	pageSubtitle?: string;
	customClass?: string;
	customStyle?: object;
	hasDot?: boolean;
}

const PageSubtitle = ({
	pageSubtitle,
	customClass,
	customStyle,
	portal,
	hasDot
}: IPageSubtitleProps) => {
	const classes = handleClasses(
		portal,
		adminPortalStyles(),
		userPortalStyles(),
		enterprisePortalStyles()
	);

	return (
		<h1 className={`${classes.pageSubtitle} ${customClass}`} style={customStyle}>
			{hasDot && <span className={classes.pageSubtitleDot}>&#9679;</span>}
			<span>{pageSubtitle}</span>
		</h1>
	);
};

export default PageSubtitle;
