import { createStyles, makeStyles } from '@material-ui/core';

import { ICustomTheme } from 'admin/styles/theme';

export const userPortalStyles = makeStyles((theme: ICustomTheme) =>
	createStyles({
		accordionPanel: {
			border: `1px solid ${theme.colors.silver}`,
			boxShadow: 'unset !important',
			margin: '0 0 8px 0',
			borderRadius: '4px !important',
			overflow: 'hidden',
			'&:before': {
				backgroundColor: 'transparent !important'
			},
			'&.Mui-expanded': {
				margin: '0 0 8px 0 !important'
			}
		},
		accordionSummary: {
			backgroundColor: '#4A91E2 !important',
			width: '100%',
			padding: '0 16px',
			maxHeight: '65px !important',
			minHeight: '65px !important',
			border: 'unset !important',
			borderBottom: '1px solid #60AAFF !important',
			'& p': {
				display: 'flex',
				alignItems: 'center',
				color: '#fff',
				fontWeight: 500,
				fontSize: '16px',
				'& span': {
					display: 'flex',
					alignItems: 'center',
					marginRight: '30px'
				}
			}
		},
		accordionDetails: {
			padding: '0px !important'
		},
		accordionArrow: {
			color: '#fff'
		},
		centeredAccordionSummary: {
			'& .MuiAccordionSummary-content': {
				justifyContent: 'center'
			}
		},
		smallAccordionSummary: {
			maxHeight: '35px !important',
			minHeight: '35px !important'
		},
		alwaysExpanded: {
			cursor: 'default !important'
		}
	})
);
