import { CircularProgress } from '@material-ui/core';
import { handleClasses } from 'shared/utils/handleClasses';

import { adminPortalStyles } from './adminPortalStyles';
import { enterprisePortalStyles } from './enterprisePortalStyles';
import { userPortalStyles } from './userPortalStyles';

interface ILoaderProps {
	portal?: 'adminPortal' | 'userPortal' | 'enterprisePortal';
	customClass?: string;
	size?: number;
}

const Loader = ({
	portal = 'adminPortal',
	customClass,
	size = 25
}: ILoaderProps) => {
	const classes = handleClasses(
		portal,
		adminPortalStyles(),
		userPortalStyles(),
		enterprisePortalStyles()
	);

	return (
		<div className={`${classes.loadingAnimation} ${customClass}`}>
			<CircularProgress size={size} thickness={3} />
		</div>
	);
};

export default Loader;
