import { createStyles, makeStyles } from '@material-ui/core';

export const styles = makeStyles(() =>
	createStyles({
		enterpriseNavigationWrapper: {
			border: '1px solid #CBD5E1',
			width: '476px',
			boxShadow:
				'0px 16px 16px -3px rgba(150, 167, 200, 0.1), 0px 8px 8px -4px rgba(150, 167, 200, 0.1)',
			borderRadius: '4px',
			backgroundColor: '#fff',
			position: 'absolute',
			top: '40px',
			right: '0px',
			zIndex: 999,
			cursor: 'default',

			'& > div': {
				height: '52px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				borderBottom: '1px solid #CBD5E1',
				padding: '0 14px',

				'&:first-of-type': {
					'& > div': {
						'& > p': {
							maxWidth: '238px'
						}
					}
				},

				'&:last-of-type': {
					borderBottom: 'none',
					justifyContent: 'unset',
					gap: '10.5px',
					cursor: 'pointer',
					width: 'fit-content',

					'& > p': {
						fontWeight: 600,
						fontSize: '16px',
						color: '#374151'
					}
				},

				'& > div': {
					display: 'flex',
					alignItems: 'center',
					gap: '10.5px',

					'& > p': {
						fontWeight: 600,
						maxWidth: '220px',
						fontSize: '16px',
						color: '#374151',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						textTransform: 'capitalize',
						'& > p': {
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							textTransform: 'capitalize'
						}
					}
				},

				'& > button': {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '169px',
					height: '32px',
					outline: 'none',
					border: 'none',
					borderRadius: '4px',
					color: '#FFF',
					fontWeight: 600,
					fontSize: '14px',
					backgroundColor: '#3B82F6',
					cursor: 'pointer',

					'&:disabled': {
						backgroundColor: '#BDCAE0',
						cursor: 'not-allowed'
					}
				}
			}
		}
	})
);
