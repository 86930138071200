import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import IPreliminaryQuestion from 'shared/models/PreliminaryQuestion';
import { listShortQuestions } from './actions';

interface IState {
	isEditable: boolean;
	questions: IPreliminaryQuestion[];
	questionsLoading: boolean;
}

const initialState: IState = {
	isEditable: false,
	questions: [],
	questionsLoading: false
};

export const shortRiskAssesssmentSlice = createSlice({
	name: 'shortRiskAssesssment',
	initialState,
	reducers: {
		setShortRaEditable: (state, action: PayloadAction<boolean>) => {
			state.isEditable = action.payload;
		},
		setShortQuestions: (state, actions: PayloadAction<IPreliminaryQuestion[]>) => {
			state.questions = actions.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(listShortQuestions.pending, (state) => {
				state.questionsLoading = true;
			})
			.addCase(listShortQuestions.fulfilled, (state, action) => {
				state.questions = action.payload;
				state.questionsLoading = false;
			});
	}
});

export const { setShortRaEditable, setShortQuestions } =
	shortRiskAssesssmentSlice.actions;

export const shortRiskAssessmentReducer = shortRiskAssesssmentSlice.reducer;
