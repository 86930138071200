import { Navigate, RouteObject } from 'react-router-dom';

import * as LazyComponent from 'user/utils/LazyComponents';
import * as PathNames from 'user/routes/pathNames';
import { UserType } from 'shared/models/User';

interface IRoute extends RouteObject {
	userTypes?: UserType[];
}

const routes: IRoute[] = [
	{
		path: PathNames.DOCUMENT_AND_POLICIES,
		element: <LazyComponent.DocumentsAndPolicies />,
		children: [
			{
				path: PathNames.DOCUMENT_AND_POLICIES,
				element: <LazyComponent.DocumentsAndPoliciesView />
			},
			{
				path: PathNames.HIPAA_RISK_ASSESSMENT,
				element: <LazyComponent.HipaaRiskAssessment />
			}
		]
	},
	{
		path: PathNames.REPORT_INCIDENT,
		element: <LazyComponent.ReportIncident />
	},
	{
		path: PathNames.EMERGENCY_PLANNING,
		element: <LazyComponent.EmergencyPlanning />
	},
	{
		path: PathNames.HIPAA_RESOURCES,
		element: <LazyComponent.HipaaResources />
	},
	{
		path: PathNames.CHECKLIST,
		element: <LazyComponent.HipaaChecklist />
	},
	{
		path: PathNames.DOCUMENTS,
		element: <LazyComponent.Documents />
	},
	{
		path: PathNames.SAFETY_DATA_SHEETS,
		element: <LazyComponent.SafetyDataSheets />
	},
	{
		path: PathNames.SELF_ASSESSMENT,
		element: <LazyComponent.SelfAssessment />
	},
	{
		path: PathNames.LOG_EXPOSURE,
		element: <LazyComponent.LogExposure />
	},
	{
		path: PathNames.OSHA_RESOURCES,
		element: <LazyComponent.OshaResources />
	},
	{
		path: PathNames.SAVINGS_CALCULATOR,
		element: <LazyComponent.SavingsCalculator />
	},
	{
		path: PathNames.HIPAA_TRAINING,
		element: <LazyComponent.HipaaTraining />,
		children: [
			{
				path: PathNames.HIPAA_TRAINING,
				element: <LazyComponent.HipaaTrainingList />
			},
			{
				path: PathNames.VIEW_HIPAA_TRAINING,
				element: <LazyComponent.HipaaTrainingView />
			},
			{
				path: PathNames.VIEW_HIPAA_SUCCESS,
				element: <LazyComponent.HipaaTrainingSuccess />
			}
		]
	},
	{
		path: PathNames.OSHA_TRAINING,
		element: <LazyComponent.OshaTraining />,
		children: [
			{
				path: PathNames.OSHA_TRAINING,
				element: <LazyComponent.OshaTrainingList />
			},
			{
				path: PathNames.VIEW_OSHA_TRAINING,
				element: <LazyComponent.OshaTrainingView />
			},
			{
				path: PathNames.VIEW_OSHA_SUCCESS,
				element: <LazyComponent.OshaTrainingSuccess />
			}
		]
	},
	{
		path: PathNames.DENTAL_TRAINING,
		element: <LazyComponent.DentalTraining />
	},
	{
		path: PathNames.USERS,
		element: <LazyComponent.Users />
	},
	{
		path: PathNames.SERVICES,
		element: <LazyComponent.Services />
	},
	{
		path: PathNames.REFERRALS,
		element: <LazyComponent.Referrals />
	},
	{
		path: PathNames.MY_ACCOUNT,
		element: <LazyComponent.MyAccount />
	},
	{
		path: '*',
		element: <Navigate replace to={PathNames.DOCUMENT_AND_POLICIES} />
	}
];

export default routes;
