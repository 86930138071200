import { createStyles, makeStyles } from '@material-ui/core';

export const styles = makeStyles(() =>
	createStyles({
		warningIconWrapper: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '12px',
			height: '12px',
			borderRadius: '50%',
			border: '1px solid',
			'& > svg': {
				fontSize: '10px'
			}
		},
		yellowIcon: {
			borderColor: '#FDC500',
			'& > svg': {
				color: '#FDC500'
			}
		},
		redIcon: {
			borderColor: '#FD8888',
			'& > svg': {
				color: '#FD8888'
			}
		}
	})
);
