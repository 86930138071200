import { createStyles, makeStyles } from '@material-ui/core';

export const userPortalStyles = makeStyles(() =>
	createStyles({
		textFormField: {
			display: 'flex',
			alignItems: 'center',
			flex: 1
		},
		isVertical: {
			flexDirection: 'column',
			alignItems: 'unset',
			justifyContent: 'space-between',
			'& > div': {
				marginTop: '8px'
			}
		},
		textField: {
			'&.MuiFormControl-root': {
				width: '100%',
				backgroundColor: '#fff',
				'& .MuiInputBase-root': {
					'& .MuiOutlinedInput-notchedOutline': {
						border: '1px solid #D1D1D1',
						transition: 'all .25s ease-in-out',
						backgroundColor: 'transparent',
						inset: '0',
						'& > legend': { display: 'none' }
					},
					'&.Mui-focused': {
						'& .MuiOutlinedInput-notchedOutline': {
							border: '1px solid #0064B9'
						}
					},
					'& input': {
						padding: '13.7px 16px',
						color: '#333',
						textOverflow: 'ellipsis',
						fontSize: '14px',
						'&::placeholder': {
							fontSize: '14px',
							color: 'rgba(51, 51, 51, 0.6)'
						}
					},
					'&.Mui-error': {
						'& .MuiOutlinedInput-notchedOutline': {
							border: '1px solid #e51c23'
						}
					},
					'&.Mui-disabled': {
						opacity: '0.7',
						'& input': {
							cursor: 'not-allowed'
						}
					}
				}
			}
		}
	})
);
