import { SyntheticEvent, useEffect, useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ArrowDropDown, KeyboardArrowDown } from '@material-ui/icons';

import { adminPortalStyles } from './adminPortalStyles';
import { handleClasses } from 'shared/utils/handleClasses';
import { userPortalStyles } from './userPortalStyles';

interface IAccordionPanel {
	title: string | JSX.Element;
	titleIcon?: JSX.Element;
	component: JSX.Element;
}

interface ICustomAccordionProps {
	portal: 'adminPortal' | 'userPortal';
	accordionPanels: IAccordionPanel[];
	defaultExpanded?: boolean;
	isTitleCentered?: boolean;
	alwaysExpanded?: boolean;
	hasExpandIcon?: boolean;
	selectedPanel?: number;
	customClass?: string;
	isSmall?: boolean;
}

const CustomAccordion = ({
	isTitleCentered = false,
	alwaysExpanded = false,
	hasExpandIcon = true,
	isSmall = false,
	defaultExpanded,
	accordionPanels,
	selectedPanel,
	customClass,
	portal
}: ICustomAccordionProps) => {
	const [expandedPanel, setExpandedPanel] = useState(selectedPanel);
	const classes = handleClasses(portal, adminPortalStyles(), userPortalStyles());

	useEffect(() => setExpandedPanel(selectedPanel), [selectedPanel]);

	useEffect(() => {
		if (defaultExpanded) {
			setExpandedPanel(0);
		}
	}, []);

	const handleChange =
		(panel: number) => (event: SyntheticEvent, isExpanded: boolean) => {
			setExpandedPanel(isExpanded ? panel : undefined);
		};

	return (
		<>
			{accordionPanels.map((panel, index) => (
				<Accordion
					className={`${classes.accordionPanel} ${customClass}`}
					expanded={alwaysExpanded || expandedPanel === index}
					onChange={handleChange(index)}
					key={index}
				>
					<AccordionSummary
						expandIcon={
							hasExpandIcon &&
							((portal === 'adminPortal' && (
								<KeyboardArrowDown className={classes.accordionArrow} />
							)) ||
								(portal === 'userPortal' && (
									<ArrowDropDown className={classes.accordionArrow} />
								)))
						}
						className={`${isTitleCentered && classes.centeredAccordionSummary}
							${isSmall && classes.smallAccordionSummary}
							${alwaysExpanded && classes.alwaysExpanded}
							${classes.accordionSummary}`}
					>
						<p>
							{panel.titleIcon && <span>{panel.titleIcon}</span>}
							{panel.title}
						</p>
					</AccordionSummary>
					<AccordionDetails className={classes.accordionDetails}>
						{panel.component}
					</AccordionDetails>
				</Accordion>
			))}
		</>
	);
};

export default CustomAccordion;
