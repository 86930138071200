import { styles } from './styles';

interface IFormActionsProps {
	onClickCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	onClickReset?: (event: React.MouseEvent<HTMLInputElement>) => void;
	submitButtonValue?: string;
	isSubmitDisabled?: boolean;
	hasCancelButton?: boolean;
	hasSubmitButton?: boolean;
	hasClearButton?: boolean;
}

const FormActions = ({
	submitButtonValue = 'Submit',
	isSubmitDisabled,
	hasSubmitButton,
	hasCancelButton,
	hasClearButton,
	onClickCancel,
	onClickReset
}: IFormActionsProps) => {
	const classes = styles();

	return (
		<>
			{hasCancelButton && (
				<button
					className={`${classes.cancelButton} ${classes.formAction}`}
					onClick={onClickCancel}
				>
					Cancel
				</button>
			)}

			{hasSubmitButton && (
				<input
					className={`${classes.submitButton} ${classes.formAction}`}
					disabled={isSubmitDisabled}
					type="submit"
					value={submitButtonValue}
				/>
			)}

			{hasClearButton && (
				<input
					className={`${classes.resetButton} ${classes.formAction}`}
					onClick={onClickReset}
					type="reset"
					value="Clear"
				/>
			)}
		</>
	);
};

export default FormActions;
