import { createStyles, makeStyles } from '@material-ui/core';

import { ICustomTheme } from 'admin/styles/theme';

export const adminPortalStyles = makeStyles((theme: ICustomTheme) =>
	createStyles({
		sidebarLink: {
			display: 'flex',
			alignItems: 'center',
			padding: '8px 20px',
			color: theme.colors.text,
			fontSize: '14px',
			textDecoration: 'none',
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: theme.colors.gray
			}
		},
		sidebarIcon: {
			width: '17px',
			height: '17px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			'& svg': {
				width: '100%',
				height: '100%'
			}
		},
		sidebarLabel: {
			marginLeft: '10px'
		}
	})
);
