import moment from 'moment';

import { styles } from './styles';

const Footer = () => {
	const classes = styles();
	const currentYear = moment().format('YYYY');

	return (
		<footer className={classes.footerWrapper}>
			<p>
				Copyright © All rights reserved {currentYear} @ PCIHIPAA |{' '}
				<a href="https://pcihipaa.com/privacy-policy/">Privacy Policy</a> |{' '}
				<a href="https://pcihipaa.com/terms-of-use/">Terms of Use</a>{' '}
			</p>
		</footer>
	);
};

export default Footer;
