import { createAsyncThunk } from '@reduxjs/toolkit';

import IPreliminaryQuestion from 'shared/models/PreliminaryQuestion';
import RiskAssessmentService from 'admin/services/RiskAssessmentService';

export const listShortQuestions = createAsyncThunk<
	IPreliminaryQuestion[],
	string | undefined
>('shortRiskAssessment/listQuestions', async (resellerId) => {
	return await RiskAssessmentService.listShortQuestions(resellerId);
});
