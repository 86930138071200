import { KeyboardEvent, MouseEvent, useState } from 'react';

import { Drawer } from '@material-ui/core';
import { Menu } from '@material-ui/icons';

import { adminPortalStyles } from './adminPortalStyles';
import { handleClasses } from 'shared/utils/handleClasses';
import OfficeSafeLogo from '../officeSafeLogo';
import { userPortalStyles } from './userPortalStyles';

interface ISideDrawerProps {
	notCloseAfterClickContent?: JSX.Element;
	portal: 'adminPortal' | 'userPortal';
	closeAfterClickContent: JSX.Element;
	drawerToggle?: JSX.Element;
	customClass?: string;
}

const SideDrawer = ({
	notCloseAfterClickContent,
	drawerToggle = <Menu />,
	closeAfterClickContent,
	customClass,
	portal
}: ISideDrawerProps) => {
	const classes = handleClasses(portal, adminPortalStyles(), userPortalStyles());

	const [state, setState] = useState({
		left: false
	});

	const toggleDrawer =
		(anchor: 'left', open: boolean) => (event: KeyboardEvent | MouseEvent) => {
			if (
				event.type === 'keydown' &&
				((event as KeyboardEvent).key === 'Tab' ||
					(event as KeyboardEvent).key === 'Shift')
			) {
				return;
			}
			setState({ ...state, [anchor]: open });
		};

	return (
		<div>
			<div className={classes.drawerToggle} onClick={toggleDrawer('left', true)}>
				{drawerToggle}
			</div>
			<Drawer
				onClose={toggleDrawer('left', false)}
				open={state['left']}
				anchor="left"
			>
				<div className={`${classes.sideDrawerWrapper} ${customClass}`}>
					<div onClick={toggleDrawer('left', false)}>
						<OfficeSafeLogo customClass={classes.officeSafeLogo} portal={portal} />
					</div>
					<div>{notCloseAfterClickContent}</div>
					<div onClick={toggleDrawer('left', false)}>{closeAfterClickContent}</div>
				</div>
			</Drawer>
		</div>
	);
};

export default SideDrawer;
